import React, { useEffect, useState, useGlobal,useMemo } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, message, Checkbox, Alert, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { CitiesPicker, ImageUrl, UnitsPicker } from '../components';
import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react'
Quill.register('modules/imageResize', ImageResize)

const { TextArea } = Input;

const AnnouncementDetail = () => {
    const quillRef = useRef(null);
    let [loading, setLoading] = useState(true);
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;

    let newRecord = {
        cities: [],
        units: [],
        title: '',
        confirmDescription: '',
        sendAllUsers: false,
        type: " ",
        createdAt: new Date(),
    }
    let [userId] = useGlobal('user')

    let [data, setData] = useState(id ? [] : newRecord);
    let [errors, setErrors] = useState([]);
    let [isSmall,] = useGlobal('isSmall');
    let [cityCheck, setCityCheck] = useState(false);
    let [unitCheck, setUnitCheck] = useState(false);


    let [validationCheck, setValidationCheck] = useState(false)

    useEffect(() => {
        setLoading(true)

        get();
    }, [])

    let get = async () => {
        if (id) {
            await api.get("/api/news/" + id)
                .then(({ data: { result } }) => {
                    if (result[0]) {
                        let cities = [];
                        result[0].type = "image"
                        result[0].NewsToCity.map(z => { cities.push(z.cityId) })
                        result[0].cities = cities
                        let units = [];
                        result[0].newsToUnit.map(z => {
                            let unitdata={code:z.unitcode,name:z.name} 
                            units.push(unitdata) 
                        })
                        result[0].units = units
                    }
                    setData(result[0]);
                });
                setLoading(false)

        }
        else {
            setLoading(false)
        }
    }
    let validate = useCallback(() => {
        let errors = {};

        if (data.image_url == null)
            errors.image_url = "Zorunlu Alan";

        if (data.title == null || data.title.length === 0)
            errors.title = "Zorunlu Alan";

        if (data.type == null || data.type.length === 0)
            errors.type = "Zorunlu Alan";

        if (data.content == null || data.content.length === 0)
            errors.content = "Zorunlu Alan";


            if(data.sendAllUsers==false){
                if(!data.cities || data.cities.length===0){
                setCityCheck(true)
        }
        else{
                if(!data.units || data.units.length===0){
        setUnitCheck(true)
                }    
        }
    }
    if ((id && userId && userId.userInfo.role === "approver") && (data && data.Users && data.Users.role !== 'approver')) {
        if (data.isActive == false) {
                if (data.confirmDescription == null || data.confirmDescription.length == 0) {
                    if(userId.userInfo.role == 'approver'){
                    errors.confirmDescription = "Zorunlu Alan";}
                }
            }
        }
        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);
    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [data, validationCheck, validate]);

    let save = async () => {
        setValidationCheck(true)

        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        } else {
            if (id) {
                if (data.image_url) {
                    data.image_height = data.image_url.height || data.image_height
                    data.image_width = data.image_url.width || data.image_width
                    data.image_url = data.image_url.url || data.image_url
                }
                if (userId && userId.userInfo.role !== 'approver') {
                    data.isActive = false  
                }
                api.put("/api/home/" + id, data)
                    .then(({ data: { result_message } }) => {
                        if (result_message.type === 'success') {
                            message.success("Kayıt Başarıyla Güncellendi ...", 2);
                            history.push('/announcements')

                        } else {
                            message.error("Kayıt Güncellenemedi !", 2);

                        }
                    })
                    .catch(() => {
                        message.error("Kayıt Güncellenemedi !", 2);

                    })
            } else {
                if (data.image_url) {
                    data.image_height = data.image_url.height || data.image_height
                    data.image_width = data.image_url.width || data.image_width
                    data.image_url = data.image_url.url || data.image_url
                }
                if (userId && userId.userInfo.role !== 'approver') {
                    data.isActive = false
                    api.post("/api/home", data).then(({ data: { result_message } }) => {
                        if (result_message.type === 'success') {
                            message.success("Duyuru için onay beklenecektir", 2);
                            history.push("/announcements")

                        } else {
                            message.error("İçerik oluşturulamadı.", 2);

                        }
                    })
                } else {
                    data.isActive = true
                    api.post("/api/home", data).then(({ data: { result_message } }) => {
                        if (result_message.type === 'success') {
                            message.success("Kayıt Başarıyla Eklendi", 2);
                            history.push("/announcements")

                        } else {
                            message.error("İçerik oluşturulamadı.", 2);

                        }
                    })
                }
            }
        }
    };
    let imageHandler = () => {
        const input = document.createElement('input');
    
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
    
        input.onchange = async () => {
    
          const quill = quillRef.current.getEditor();
          const file = input.files[0];
          const formData = new FormData();
    
          formData.append('files_0', file);
    
          const range = quill.getSelection(true);
          quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
          quill.setSelection(range.index + 1);
    
          let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data' } }).then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              return result[0].url;
            }
          });
          quill.deleteText(range.index, 1);
          quill.insertEmbed(range.index, 'image', url);
        };
      }
      const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link',  'color', 'size', 'align', 'background', 'direction', 'clean']
    const modulesT = useMemo(() => ({
        toolbar: {
          container: [
            [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { align: [] },
              { direction: 'rtl' },
            ],
            ['clean'],
           // ['code-block'],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            [{ script: 'sub' }, { script: 'super' }]
          ],
        //   handlers: {
        //     image: imageHandler
        //   }
        },
        // imageResize: {
        //   parchment: Quill.import('parchment'),
        //   modules: ['Resize', 'DisplaySize']
        // }
      }), []);

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Duyurular</h1>
                </div>
                <div className="list-buttons">
                    <Link to={"/announcements"}>
                        <Button type="light" icon={<LeftOutlined />} size={'large'}>{!isSmall && "Geri"}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? "Düzenle" : "Yeni Ekle"} loading={loading}>
                    <Form layout="horizontal" size={"large"}>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Başlık" required help={errors.title}
                                    validateStatus={errors.title ? 'error' : 'success'}>
                                    <Input name="title" value={data.title} disabled={data.isDeleted}
                                        onChange={e => setData({ ...data, title: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row" >
    <Col span={24}>
      <Form.Item label={"Açıklama"} required help={errors.content} validateStatus={errors.content ? 'error' : 'success'}>
        <ReactQuill
          ref={quillRef}
          modules={modulesT}
          formats={formats}
          name="content" theme="snow" value={(data.content) ? data.content : ''} onChange={content => setData({ ...data, content: content })} />
      </Form.Item>
    </Col>
  </Row>
                        {/* <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Açıklama" required help={errors.content} validateStatus={errors.content ? 'error' : 'success'}>
                                    <TextArea rows={3} name="content" value={data.content} disabled={data.isDeleted}
                                        onChange={e => setData({ ...data, content: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Alert message={"Tüm kullanıcılar ya da il/şube bazlı şeçim yapılmalıdır."} type="warning" showIcon />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item label="Resim" required help={errors.image_url}
                                               validateStatus={errors.image_url ? 'error' : 'success'}>
                                        <ImageUrl record={data} setRecord={setData} name="image_url"/>
                                    </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Tüm Kullanıcılar" required help={errors.sendAllUsers} validateStatus={errors.sendAllUsers ? 'error' : 'success'}>
                                    <Checkbox onChange={(e) => setData({ ...data, sendAllUsers: e.target.checked })} defaultChecked={data.sendAllUsers}>
                                        </Checkbox>
                                </Form.Item>
                            </Col>

                            {!data.sendAllUsers &&
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="İller" required help={errors.cities} validateStatus={errors.cities ? 'error' : 'success'}>
                                        <CitiesPicker record={data} setRecord={setData} name="cities" />
                                    </Form.Item>
                                </Col>
                            }
                            {data.cities && !data.sendAllUsers &&
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="Şubeler" required help={errors.units} validateStatus={errors.units ? 'error' : 'success'}>
                                        <UnitsPicker record={data} setRecord={setData} name="units" />
                                        {unitCheck &&
                                    <Alert  message="Şube Seçimi Zorunludur!" banner />
                                        }
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        {(id && userId && userId.userInfo.role === "approver") && (data && data.Users && data.Users.role!=='approver') &&
                                <Row direction="row">
                                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                        <Form.Item label="Duyuruyu onayla">
                                            <Switch  checked={data.isActive ? true : false} onChange={(e) => setData({ ...data, isActive: e })} checkedChildren={"Onay"}
                                                unCheckedChildren={"Red"} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 16 }}>
                                        <Form.Item label="Onay Açıklaması" help={errors.confirmDescription} validateStatus={errors.confirmDescription ? 'error' : 'success'}>
                                            <TextArea rows={3} name="confirmDescription" value={data.confirmDescription} disabled={data.isDeleted}
                                                onChange={e => setData({ ...data, confirmDescription: e.target.value })} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        <Row direction="row">
                            <Col xs={24}>
                                <Form.Item>
                                    <Button
                                        onClick={save}
                                        style={{ backgroundColor: '#e12518', color: 'white' }} disabled={data.isDeleted}
                                        htmlType="submit" size="large"
                                        block> {"Yayınla"} </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </div>
        </div>
    );
};


export default AnnouncementDetail;
