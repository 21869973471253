import React, {useEffect, useState} from "react";
import {useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import {Row, Col, Form, Input, Button, Card, DatePicker, message, Select} from 'antd';
import {useHistory} from 'react-router-dom';
import {LeftOutlined} from "@ant-design/icons";
import api from '../service/index'
import {Link} from 'react-router-dom';
import {useCallback} from "react";
import moment from "moment";
import {ImageUrl} from "../components";
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';


const {TextArea} = Input;
const {Option} = Select;

const ControlNotificationDetail = () => {
    let params = useParams()
    let history = useHistory()
    let announcement = params.id !== "add" ? params.id : false;
    let id = params.id !== "add" ? params.id : false;

    let newRecord = {
        title: '',
        image: "",
        imageWidth: "",
        imageHeight: ""
    }

    let [data, setData] = useState(id ? [] : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(true);
    let [isSmall,] = useGlobal('isSmall')

    let [validationCheck, setValidationCheck] = useState(false)

    useEffect(() => {
        setLoading(true)
        get();
    }, [])

    let get = async () => {
        if (id) {
            await api.get("/api/control-notify/" + id).then(({data: {result, result_message}}) => {
                setData(result[0]);
                setLoading(false)
            });
        } else {
            setLoading(false)
        }
    }

    let validate = useCallback(() => {
        let errors = {};

        if (data.description == null || data.description.length === 0)
            errors.description = "Zorunlu Alan";

        if (data.controlType === null || data.controlType === undefined)
            errors.gunCare = "Kotrol Tipi Seçin";

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({top: 20, behavior: 'smooth'});
        } else {
            if (id) {
                if (data.image && data.image.url) {
                    data.imageWidth = String(data.image.width)
                    data.imageHeight = String(data.image.height)
                    data.image = String(data.image.url)
                }
                api.put("/api/control-notify/" + id, data)
                    .then(({data: {result_message}}) => {
                        if (result_message.type === 'success') {
                            message.success("Kayıt Başarıyla Güncellendi ...", 2);
                            history.push('/controlNotifications')
                        } else
                            message.error("Kayıt Güncellenemedi !", 2);
                    })
                    .catch(err => {
                        message.error("Kayıt Güncellenemedi !", 2);
                    })
            }
        }
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Kontrol Bildirimleri</h1>
                </div>
                <div className="list-buttons">
                    <Link to={"/controlNotifications"}>
                        <Button type="light" icon={<LeftOutlined/>} size={'large'}>{!isSmall && "Geri"}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? "Düzenle" : "Yeni Ekle"} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="Karne Tipi" help={errors.controlType}
                                           validateStatus={errors.controlType ? 'error' : 'success'}>
                                    <Select disabled
                                            defaultValue={data.controlType === 0 ? "Silah Bakımı" : data.controlType === 1 ? "YSC Aylık Kontrol" : ""}
                                            style={{
                                                width: 120,
                                            }}
                                            onChange={e => setData({...data, controlType: e})}
                                    >
                                        <Option value={0} >Silah Bakımı</Option>
                                        <Option value={1} >YSC Aylık Kontrol</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {data.controlType === 0 || id &&
                                (<>
                                    <Col xs={{span: 24}} md={{span: 10}}>
                                        <Form.Item label="Media" validateStatus={errors.image ? 'error' : 'success'}>
                                            <ImageUrl record={data} setRecord={setData} name="image" disabled/>
                                        </Form.Item>
                                    </Col>
                                </>)}
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="Şube/Birim">
                                    <Input name="controlUnit" value={data && data.Users && data.Users.branchName}
                                           disabled/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="Var/Yok">
                                    <Select style={{width: 120,}} disabled
                                            defaultValue={data.isControl ===true ? "Var" : "Yok"}>
                                        <Option value={1}>Var</Option>
                                        <Option value={0}>Yok</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="Bildirimi Yapan">
                                    <Input name="description" value={data && data.Users && data.Users.name+" "+ data.Users.surname}
                                           disabled/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={'Bildirim/Bakım Tarihi'}>
                                    <DatePicker locale={locale} disabled
                                        defaultValue={() => moment(data.createdAt)}
                                        format="DD/MM/YYYY HH:mm" showTime={true}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 24}}>
                                <Form.Item label="Açıklama">
                                    <TextArea disabled rows={5} name="description" value={data.description}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};


export default ControlNotificationDetail;
