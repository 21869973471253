import React, {useEffect, useState, useGlobal} from 'reactn';
import {useParams} from 'react-router-dom';
import {Row, Col, Form, Input, Button, Card,
    message, Select, Modal, Alert} from 'antd';
import {useHistory} from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';
import {ImageUrlIdentity} from '../components';
import api from '../service/index';
import {Link} from 'react-router-dom';
import {useCallback} from 'react';
import {env} from '../app.config';
import FileBase from "react-file-base64"
import 'react-phone-input-2/lib/style.css';

const {Option} = Select;

const AttendeeDetail = () => {
    let params = useParams();
    let history = useHistory();
    let id = params.id !== 'add' ? params.id : false;

    let newRecord = {
        name: '',
        surname: '',
        phone: '',
        alternate_phone: '',
        image_url: '',
        imageData: 'b',
        email: '',
        title: '',
        password: '',
        role: 'user',
    };
    let loginType = env.LOGINTYPE;
    let [data, setData] = useState(id ? [] : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false);
    let [isSmall] = useGlobal('isSmall');
    const [sizeModalVisible, setIsSizeModalVisible] = useState(false);
    const [identityShowModal, setIsShowIdentityModal] = useState(false)
    const [identityInfo, setIdentityInfo] = useState({})
    const [sizeValue, setSizeValue] = useState([])
    const [sizeValues, setSizeValues] = useState([])
    const [sizeGet, setSizeGet] = useState([])

    let passMessage = '';

    if (loginType === 'phone')
        passMessage =
            "- Uygulama girişlerinde telefonunuza kod gelecektir. Bu alanı Admin kulalnıcılarının Cms'e girebilmesi için belirlemelisiniz.";

    useEffect(() => {
        if (id) {
            setLoading(true);
            api
                .get('/api/users/' + id)
                .then(async ({data: {result}}) => {

                    await api.get(`/api/user-size-get/${id}`, {}).then(({data}) => {
                        if (data.result) setSizeGet(data.result)
                        else message.error("Beden Bilgileri Getirilemedi !")
                    });

                    await api.get("/api/sizeSelection").then(({data: {result}}) => {
                        setSizeValue(result)
                    })

                    delete result.password;
                    setData(result[0]);
                    setLoading(false);

                    await api.get("/api/identity-info/" + id).then(({data: {result}}) => {
                        setIdentityInfo(result[0])
                    })
                });
        }
    }, [id]);

    useEffect(  () => {
        api.get("/api/sizeSelection")
        .then(({data: {result}}) => {
            setSizeValue(result)
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])

    let validate = useCallback(() => {
        let errors = {};

        if (data.name === null || data.name.length === 0)
            errors.name = 'Zorunlu Alan!';

        if (data.surname === null || data.surname.length === 0)
            errors.surname = 'Zorunlu Alan!';

        if (loginType === 'phone') {
            if (data.phone === null || data.phone.length === 0)
                errors.phone = 'Zorunlu Alan!';
        } else if (loginType === 'emailPass') {
            if (data.email === null || data.email.length === 0)
                errors.email = 'Zorunlu Alan!';
        } else {
            if (data.email === null || data.email.length === 0)
                errors.email = 'Zorunlu Alan!';
            if (data.phone === null || data.phone.length === 0)
                errors.phone = 'Zorunlu Alan!';
        }
        errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    const saveSizeInfo = async () => {
        setIsSizeModalVisible(false)
        /*if (id) {
            api.post("/api/sizeSelection/" + id, {"sizeValues": sizeValues})
                .then(({data: {result, result_message}}) => {
                    message.success("Bilgiler güncellendi");
                    history.push('/attendees/detail/'+ id)
                }).catch((err) => {
                console.log(err)
            })
        } else {
            api.post("/api/sizeSelection/" + id, {"sizeValues": sizeValues})
                .then((data) => {
                    message.success("Bilgiler Eklendi");
                    history.push('/attendees/detail/'+ id)
                }).catch((err) => {
                console.log(err)
            })
        }*/
    }

    const saveIdentityInfos = async () => {
        setIsShowIdentityModal(false)
        /*if (id && identityInfo !== undefined) {
            identityInfo.image1=identityInfo.image1.url ? identityInfo.image1.url : identityInfo.image1
            identityInfo.image2=identityInfo.image2.url ? identityInfo.image2.url : identityInfo.image2
            await api.put("/api/identity-info/" + id, identityInfo)
                .then(({data: {result, result_message}}) => {
                    message.success("Bilgiler güncellendi");
                    history.push('/attendees/detail/'+id)
                }).catch((err) => {
                    console.log(err)
                })
        } else {
            if (identityInfo !== undefined) {
                identityInfo.image1=identityInfo.image1.url ? identityInfo.image1.url : identityInfo.image1
                identityInfo.image2=identityInfo.image2.url ? identityInfo.image2.url : identityInfo.image2
                await api.post("/api/identity-info/", identityInfo)
                    .then((data) => {
                        message.success("Bilgiler Eklendi");
                        history.push('/attendees/detail/'+ id)
                    }).catch((err) => {
                        console.log(err)
                    })
                }
            }*/
    }
    
   const getFiles=(files)=>{
       setData({...data, imageData: files})
    }

    useEffect(() => {
        if (validationCheck) setErrors(validate());
    }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true);
        let err = validate();
        if (err.hasError) {
            setErrors(err);
            window.scrollTo({top: 20, behavior: 'smooth'});
        } else {
            if (id) {
                if (data.phone && !data.phone.startsWith('+')) {
                    data.phone = '+' + data.phone;
                }

                // if (data.image_url) data.image_url = data.image_url.url;

                api
                    .put('/api/users/' + id, data)
                    .then(({data: {result_message}}) => {
                        if (result_message.type === 'success') {
                            message.success('Bilgiler güncellendi');
                            history.push("/")
                        } else message.error(result_message.message);
                    });
            } else {
                if (data.phone && !data.phone.startsWith('+')) {
                    data.phone = '+' + data.phone;
                }
                // if (data.image_url) data.image_url = data.image_url.url;

                api.post('/api/users', data).then(({data}) => {
                    if (data.result_message.type === 'success') {
                        message.success('Kayıt başarılı bir şekilde eklendi');
                        history.push("/")
                    } else {
                        message.error(data.result_message.message);
                    }
                });
            }
        }
    };
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Kullanıcılar</h1>
                </div>
                <div className="list-buttons">
                    <Link to={"/attendees"}>
                        <Button type="light" icon={<LeftOutlined/>} size={'large'}>{!isSmall && "Geri"}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? "Düzenle" : "Yeni Ekle"} loading={loading}>
                    <Form layout="horizontal" size={"large"}>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item 
                                    label={'İsim'}
                                    required
                                    help={errors.name}
                                    validateStatus={errors.name ? 'error' : 'success'}
                                >
                                    <Input disabled={true}
                                        name="name"
                                        value={data.name}
                                        onChange={(e) => setData({...data, name: e.target.value})}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item
                                    label={'Soyisim'}
                                    required
                                    help={errors.surname}
                                    validateStatus={errors.surname ? 'error' : 'success'}
                                >
                                    <Input
                                        name="surname" disabled={true}
                                        value={data.surname}
                                        onChange={(e) =>
                                            setData({...data, surname: e.target.value})
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            </Row>
                            <Row direction="row">

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item
                                    label={'E-mail'}
                                    required={loginType === 'emailPass' ? true : false}
                                    help={errors.email}
                                    validateStatus={errors.email ? 'error' : 'success'}
                                >
                                    <Input
                                        name="email" disabled={true}
                                        value={data.email}
                                        onChange={(e) =>
                                            setData({...data, email: e.target.value})
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item
                                    label={'Kurumsal Telefon No'}
                                    required={loginType === 'phone' ? true : false}
                                    help={errors.phone}
                                    validateStatus={errors.phone ? 'error' : 'success'}
                                >
                                    <Input
                                        name="phone" disabled={true}
                                        defaultValue={data.phone}
                                        onChange={(e) =>
                                            setData({...data, phone: e.target.value})
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            </Row>
                            <Row direction="row">

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item
                                    label={'Alternatif Telefon No'}
                                    required={loginType === 'phone' ? true : false}
                                    help={errors.alternate_phone}
                                    validateStatus={errors.alternate_phone ? 'error' : 'success'}
                                >
                                    <Input disabled={true}
                                        name="alternative_phone"
                                        defaultValue={data.alternate_phone}
                                        onChange={(e) =>
                                            setData({...data, alternate_phone: e.target.value})
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            {/*<Col xs={{span: 12}} md={{span: 12}}>
                                <Form.Item label="Avatar">
                                <FileBase type={"file"} multiple={false} disabled={true}
                                       onDone={({base64}) => getFiles(base64)}/>
                                    {/* <ImageUrl
                                        record={data}
                                        setRecord={setData}
                                        name="image_url"
                                    /> 
                                </Form.Item>
                            </Col>*/}
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item
                                    label="Rol"
                                    required
                                    help={errors.role}
                                    validateStatus={errors.role ? 'error' : 'success'}
                                >
                                    <Select
                                        defaultValue={data.role ? data.role : 'user'}
                                        style={{width: 120}}
                                        onChange={(v) => {
                                            setData({...data, role: v});
                                        }}
                                    >
                                        <Option value="user">Kullanıcı</Option>
                                        <Option value="contributer">Girişçi</Option>
                                        <Option value="approver">Onaycı</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            {sizeModalVisible && (
                                <>
                                    <Modal
                                        title="Ürünler"
                                        visible={sizeModalVisible}
                                        onOk={saveSizeInfo}
                                        cancelButtonProps={{ style: { display: 'none' } }}
                                        okText="Tamam"
                                        onCancel={saveSizeInfo}
                                    >
                                        <label style={{fontSize: 'medium'}}>Mont</label>
                                        <Select disabled={true}
                                            defaultValue={ sizeGet ? sizeGet.Mont.length > 0 ? sizeGet.Mont : 'Mont' : 'Mont'}
                                            style={{width: 80}}
                                            onChange={e => 
                                                sizeValue[0].SizeValues.map((item) => {
                                                    if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                                })
                                            }>
                                            {sizeValue[0].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Kaban</label>
                                        <Select disabled={true}
                                            defaultValue={ sizeGet ? sizeGet.Kaban.length > 0 ? sizeGet.Kaban : 'Kaban' : 'Kaban'}
                                            style={{width: 80}}   onChange={e => sizeValue[1].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[1].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Tişört</label>
                                        <Select disabled={true}
                                            defaultValue={ sizeGet ? sizeGet.TShirt.length > 0 ? sizeGet.TShirt : 'T-Shirt' : 'T-Shirt'}
                                            style={{width: 80}}   onChange={e => sizeValue[3].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[3].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Pantolon</label>
                                        <Select disabled={true}
                                            defaultValue={ sizeGet ? sizeGet.Pantolon.length > 0 ? sizeGet.Pantolon : 'Pantolon' : 'Pantolon'}
                                            style={{width: 80}}   onChange={e => sizeValue[2].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[2].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>
                                                    {item.value}
                                                </Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Yazlık Ayakkabı</label>
                                        <Select disabled={true}
                                            defaultValue={ sizeGet ? sizeGet.YazlikAyakkabi.length > 0 ? sizeGet.YazlikAyakkabi : 'Yazlık Ayakkabı': 'Yazlık Ayakkabı' }
                                            style={{width: 80}}   onChange={e => sizeValue[4].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[4].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>
                                                    {item.value}
                                                </Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Kışlık Ayakkabı</label>
                                        <Select disabled={true}
                                            defaultValue={ sizeGet ? sizeGet.KislikAyakkabi.length > 0 ? sizeGet.KislikAyakkabi : 'Kışlık Ayakkabı' : 'Kışlık Ayakkabı' }
                                            style={{width: 80}}  onChange={e => sizeValue[5].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[5].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                    </Modal>
                                </>
                            )}
                            { identityShowModal  && (
                                <Modal title="Kimlik Belgeleri" visible={identityShowModal}
                                       onOk={saveIdentityInfos}                                         
                                       cancelButtonProps={{ style: { display: 'none' } }}
                                       okText="Tamam"
                                       onCancel={saveIdentityInfos}>
                                        
                                    <Alert
                                        message={
                                            'Emniyet Genel Müdürlüğü tarafından verilen güvenlik görevlisi kimlik belgesini yükleyiniz'}
                                        type="info"
                                        showIcon
                                    />
                                    <div className={'modal__show'}>
                                        <ImageUrlIdentity disabled={true}
                                            style={{marginTop: '.50rem'}}
                                            record={identityInfo}
                                            setRecord={setIdentityInfo}
                                            name="image1"
                                        />
                                        <ImageUrlIdentity disabled={true}
                                            style={{marginTop: '.50rem'}}
                                            record={identityInfo}
                                            setRecord={setIdentityInfo}
                                            name="image2"
                                        />
                                    </div>
                                    <Row direction={'row'}>
                                        <Col
                                            xs={{span: 12}}
                                            md={{span: 12}}
                                            style={{paddingRight: '20px'}}
                                        >
                                            <Input disabled={true}
                                                name="date"
                                                value={identityInfo && identityInfo.effectiveDate}
                                                onChange={(e) =>
                                                    setIdentityInfo({
                                                        ...identityInfo,
                                                        effectiveDate: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col xs={{span: 12}} md={{span: 12}}>
                                            <Input disabled={true}
                                                name="date"
                                                value={identityInfo && identityInfo.organiseDate}
                                                onChange={(e) =>
                                                    setIdentityInfo({
                                                        ...identityInfo,
                                                        organiseDate: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Modal>
                            )}
                        </Row>
                        <Row direction="row">
                            {params.id && (
                                <>
                                    <Col xs={{span: 24}} md={{span: 12}}>
                                        <Form.Item label="Beden Ölçüleri" validateStatus={errors.size ? 'error' : 'success'}>
                                            <Button onClick={() => setIsSizeModalVisible(true)}>Göster</Button>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 12}}>
                                        <Form.Item label="Kimlik Belgeleri" validateStatus={errors.size ? 'error' : 'success'}>
                                            <Button onClick={() => setIsShowIdentityModal(true)}>Göster</Button>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                           
                            </Row>
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                     onClick={save}
                                        style={{backgroundColor: '#e12518', color: 'white'}}
                                        disabled={loading}
                                        htmlType="submit"
                                        size="large"
                                        block
                                    >
                                        {' '}
                                        Kaydet{' '}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};
export default AttendeeDetail;
