import React from 'react';
import {useGlobal} from "reactn";
import {Avatar, Dropdown, Menu} from 'antd';
import Cookies from 'js-cookie'

const HeaderBar = () => {

    let [, setToken] = useGlobal("token");
    let [userId] = useGlobal('user')

    // let [user,] = useGlobal("user");

    let logout = (props) => {
        Cookies.remove('token');
        window.localStorage.clear()
        setToken(null)
    }
    const menu = (
        <Menu className="head-menu">
            <Menu.Item onClick={logout} danger>
                {"Çıkış Yap"}
            </Menu.Item>
        </Menu>
    );
    return (
        <div className="topMenu">
            <p><b>{userId && userId.userInfo && userId.userInfo.name +" "+userId.userInfo.lastname}</b></p>
            <Dropdown overlay={menu} placement="bottomRight" arrow>
                <div>
                    <Avatar onClick={(e) => e.preventDefault()} className="ant-dropdown-link"
                            src="/images/default-avatar.svg"/>
                </div>
            </Dropdown>
        </div>
    );
};


export default HeaderBar;
