import React from "react";
import {useGlobal} from "reactn";
import {Form, Input, Button} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import api from './../service';
import Cookies from 'js-cookie';
import {useHistory} from "react-router-dom";
import logo from "../assets/logo/loginLogo.png";

const Login = () => {
    const history = useHistory();

    if (Cookies.get("token")) history.push("/")

    let [, setToken] = useGlobal("token");
    let [, setUser] = useGlobal("user");

    const auth = async ({email, password}) => {
        let params = {
            "email": email,
            "password": password,
        }

        delete api.headers['Authorization'];
        await api.post('/api/loginAdmin', params).then(async ({data}) => {
            if (data.result) {
                setToken(data.result.token);
                setUser(data.result);
                localStorage.setItem("user", JSON.stringify(data.result));
    
                api.setHeader('Authorization', "Bearer " + data.result.token);
    
                Cookies.set("token", data.result.token)
                history.push("/");
            } 
            else if (data.result_message) {
                alert(data.result_message.message);
            }
        }).catch(() => {
            alert('Kullanıcı adı veya şifre hatalıdır.')
        });
    };

    const onFinish = (values) => {
        auth(values);
    };

    return (
        <div className="login-wrap">
            <div className="form">
                < Form name="normal_login" className="login-form" size="large" initialValues={{remember: true,}} onFinish={onFinish}>
                    <div style={{width: "50vh", marginTop: "6rem", backgroundColor: "#fff", padding: "1rem", borderRadius:  "8px"}}>
                        <div className="logo">
                            <img src={logo} alt={`logo`} />
                        </div>
                        <div>
                            <Form.Item className="mt-3"
                                name="email" rules={[{required: true, message: 'Lütfen kullanıcı adınızı giriniz!',},]}>
                                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
                            </Form.Item>
                            <Form.Item name="password" rules={[{required: true, message: 'Lütfen şifrenizi giriniz!',},]}>
                                <Input prefix={<LockOutlined className="site-form-item-icon"/>} type="password" placeholder="Şifre"/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="light" htmlType="submit" className="login-form-button" id="1">Giriş yap</Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}
export default Login;
