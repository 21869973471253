import React, {useEffect, useGlobal, useState} from "reactn";
import {Link, useParams} from 'react-router-dom'
import {Button, Popconfirm, Space, Table, Input} from 'antd';
import api from '../service/index'
import qs from 'qs';
import {DeleteOutlined, EditOutlined, Loading3QuartersOutlined, PlusOutlined} from "@ant-design/icons";

const OurBank = () => {
    const { Search } = Input;
    let params = useParams()
    let query = []
    query["category"] = params.id !== "add" ? params.id : false;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [loading, setLoading] = useState(false)
    let [isSmall,] = useGlobal('isSmall')
    let [unchangedData, setUnchangedData] = useState([])

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        let restData = await api.get(`/api/about_bank?${qs.stringify(_params)}`, _params)
            .then(({data}) => {
                if (data && data.result) {
                    setTotalCount(data.result.person.length);
                    setLoading(false)
                    return data.result.person.map((item, key) => {
                        item.key = key;
                        return item;
                    })
                }
            }).catch(err => {
                console.log(err);
            });
        setData(restData);
        setUnchangedData(restData)
    }

    useEffect(() => {
        setLoading(true)
        get();
    }, [])

    const filter = (e) => {
        const val = e.target.value.toLocaleLowerCase();
        const tempList = Object.assign([], unchangedData);
        if (val === '' || !val) {
            setData(unchangedData)
            return;
        }
        let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
        setData(filteredData)
      }
    
      const isContainsFilterValue = (t, val) => {
        const isContains2 = t.title == null ? false : t.title.toLowerCase().indexOf(val) !== -1;
        const isContains3 = t.content == null ? false : t.content.toLowerCase().indexOf(val) !== -1;
        const isContains4 = t.phone == null ? false : t.phone.toLowerCase().indexOf(val) !== -1;
        return isContains2 || isContains3 || isContains4
    }

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let deleteRow = async (item_id) => {
        await api.delete(`/api/about_bank/${item_id}`, () => {
        });
        let newData = data.filter(el => el.id !== item_id);
        setData(newData);
    }

    let deleteSelectedRows = async () => {
        await selectedRows.forEach(item => {
            api.delete(`/api/about_bank/${item.id}`, () => {
            });
            let newData = data.filter(el => el.id !== item.id);
            setData(newData);
            get();
        })
        window.location.reload(false);
    }

    let columns = [
        {
            title: "Resim",
            dataIndex: 'image_url',
            key: 'image_url',
            sorter:(a, b) => a.image_url.localeCompare(b.image_url),
            sortDirections: ['descend', 'ascend'],
            render: (text) => {
                return <img src={text} alt="" width={50} height={50}/>
            }
        },
        {
            title: "İsim",
            dataIndex: 'name',
            key: 'name',
            sorter:(a, b) => a.name.localeCompare(b.name),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: "Soyisim",
            dataIndex: 'surname',
            key: 'surname',
            sorter:(a, b) => a.surname.localeCompare(b.surname),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "Bölüm",
            dataIndex: 'part',
            key: 'part',
            sorter:(a, b) => a.part.localeCompare(b.part),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "Sıra",
            dataIndex: 'order',
            key: 'order',
            sorter:(a, b) => a.order.localeCompare(b.order),
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Aksiyonlar',
            key: 'action',
            align: 'right',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/ourBank/edit/" + record.id}>
                        <Button icon={<EditOutlined/>}>
                            {!isSmall && "Düzenle"}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record.id)} title={"Bu kaydı silmek istediğinize emin misiniz?"}
                        okText="Onayla" cancelText={"Vazgeç"}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {!isSmall && "Sil"}
                        </Button>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Bankamız</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall &&
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{"Seçilenleri Sil"}</Button>
                    }
                    <Link to={"/ourBank/add"}>
                        <Button type="light" icon={<PlusOutlined/>} size={'large'}
                                style={{marginRight: "5px"}}>{!isSmall && "Yeni Ekle"}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder="Başlık - Açıklama - Telefon" onChange={filter}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange} scroll={{x: 'auto'}}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default OurBank;
