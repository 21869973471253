import React, {useEffect, useState} from 'react';
import {Select} from 'antd';
import api from '../service/index';

export default (props) => {
    let {record, setRecord, name,disabled} = props;
    let [cities, setCities] = useState([]);
    let [citiesAll, setCitiesAll] = useState(true)
    let [cityAll, setCityAll] = useState(true)
    let [all, setAll] = useState(true)

    useEffect(() => {
        api.get('/api/cities')
            .then(({data: {result}}) => {
                let dbCities = result.sort((a, b) =>
                a.name.localeCompare(b.name),
              )
                setCities(dbCities);
            });
            if((record && record.cities && record.cities.length>0  &&  record.cities.find(a=>a=='all_cities'))){
              console.log("tüm")
              setAll(false)
          }
          else if((record && record.cities && record.cities.length>0  &&  record.cities.find(a=>a!=='all_cities'))){
            setCityAll(false)
          }
    }, []);
    let selectedCities = (v) => {
      setCitiesAll(true)
        let units=[]
         if(v[0]=='all_cities'){
            //cities.map(z=>{
                //units.push(z.code.trim())
                units.push('all_cities')
                setCitiesAll(true)
            //})
        }
        else{
        units = v.map((x) => {
          // console.log({x:x})
          // let selectedcities = cities.find((e) => e.code === x);
          // console.log(selectedcities)
          setCitiesAll(false)
          return x.trim()
        });             
        }
           
        setRecord({ ...record, [name]: units });
      };
    return (
        <Select
        disabled={disabled}
            value={record[name] ? record[name]: []}

            mode="multiple"
            placeholder={"İlleri Seçin"}
           
            onChange={selectedCities}
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >

      {((citiesAll && cityAll) || (all==false)) &&  
      <Select.Option key="all_cities" value="all_cities">
        Tümü
      </Select.Option>}
      
            {(cities && all == true) && 
                cities.map((b) => (
                    <Select.Option key={b.code.trim()} value={b.code.trim()}>
                        {b.name}
                    </Select.Option>
                ))}
        </Select>
    );
};
