import React from 'react';
import {Link} from 'react-router-dom';
import {Layout, Menu} from 'antd';
import { useGlobal} from "reactn";
import userIcon from "../assets/menuIcons/user-regular.svg"
import announcementIcon from "../assets/menuIcons/home-solid.svg"
import controlIcon from "../assets/menuIcons/email-flag-unselected.svg"
import ourBankIcon from "../assets/menuIcons/ziraat-bankasi-black-vector-logo-free-11574000120qeebb0xmlm.png"
import educationIcon from "../assets/menuIcons/graduation-cap.svg"
import contactIcon from "../assets/menuIcons/contact-phone.svg"
import logo from "../assets/logo/logo.svg"
import Notifications from "../assets/menuIcons/bell-regular.svg";

const {Sider} = Layout;

const SideMenu = () => {
    const [collapsed, setCollapsed] = useGlobal('collapsed');
    let [isSmall] = useGlobal('isSmall');
    let [user,] = useGlobal("user");

    var url = {
        backgroundImage: `url(${logo})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain'
    };

    function tabTitle(titleName) {
        window.scrollTo({top: 0, behavior: 'smooth'});
        //document.title = cpName + " - " + titleName;
    }

    const logos =[{id : 1,name:"Kullanıcılar",key:"attendee",currentPage:"/attendees",imgUrl:userIcon},
                  {id : 2,name:"Duyurular",key:"announcements",currentPage:"/announcements",imgUrl:announcementIcon},
                  {id : 3,name:"Kontrol Bildirimleri",key:"controlNotifications",currentPage:"/controlNotifications",imgUrl:controlIcon},
                  {id : 4,name:"Bankamız",key:"ourBank",currentPage:"/ourBank",imgUrl:ourBankIcon},
                  {id : 5,name:"Eğitim",key:"education",currentPage:"/education",imgUrl:educationIcon},
                  {id : 6,name:"İletişim",key:"contact",currentPage:"/contact",imgUrl:contactIcon},
                  {id : 7,name:"Bildirimler",key:"notification",currentPage:"/notifications",imgUrl:Notifications}]

    const getMenu = () => {
        return (
                <>
                    {
                        logos.map((item)=>(
                            <Menu.Item key={item.id} onClick={() => tabTitle(item.name)}>
                                <Link to={item.currentPage}>
                                     <img alt="" src={item.imgUrl} width="45px"/>
                                    <span className={"menu__icon"}>{item.name}</span>
                                </Link>
                            </Menu.Item>
                        ))
                    }
                </>
            );
    }

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} theme="light"
               width={isSmall ? '100' : '180'}>
            <div className="logo-wrap">
                <div className="logo" style={url}></div>
            </div>
            <Menu theme="light" mode="inline">
                {getMenu()}
            </Menu>
        </Sider>
    );
};


export default SideMenu;