import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import {Table, Space, Button, Popconfirm, Input, message} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {
    EyeInvisibleOutlined, EditOutlined, EyeOutlined,
    PlusOutlined, Loading3QuartersOutlined, FolderViewOutlined, DeleteOutlined, DownloadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
import { CitiesUnitsPicker} from "../components";

const Announcements = () => {
    const {Search} = Input;
    let params = useParams()
    let query = []
    let activity = params.id !== "add" ? params.id : false;
    query["category"] = activity;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [loading, setLoading] = useState(false)
    let [isSmall,] = useGlobal('isSmall')
    let [unchangedData, setUnchangedData] = useState([])
    let [deletedDatas, setDeletedDatas] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    let [modalData, setModalData] = useState([]);

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        let restData = await api.get(`/api/news?${qs.stringify(_params)}`, _params)
            .then(({data}) => {
                if (data && data.result) {
                    if (deletedDatas) {
                        data.result.news = data.result.news.filter(x => x.isDeleted)
                        setTotalCount(data.result.total);
                        setLoading(false)
                        return data.result.news.map((item, key) => {
                            item.key = key;
                            return item;
                        })
                    } else {
                        data.result.news = data.result.news.filter(x => !x.isDeleted)
                        setTotalCount(data.result.total);
                        setLoading(false)
                        return data.result.news.map((item, key) => {
                            item.key = key;
                            return item;
                        })
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
        setData(restData);
        setUnchangedData(restData)
    }

    useEffect(() => {
        setLoading(true)
        get();
    }, [deletedDatas])

    const filter = (e) => {
        const val = e.target.value.toLocaleLowerCase();
        const tempList = Object.assign([], unchangedData);
        if (val === '' || !val) {
            setData(unchangedData)
            return;
        }
        let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
        setData(filteredData)
    }

    const isContainsFilterValue = (t, val) => {
        const isContains2 = t.title == null ? false : t.title.toLowerCase().indexOf(val) !== -1;
        const isContains3 = t.content == null ? false : t.content.toLowerCase().indexOf(val) !== -1;
        return isContains2 || isContains3;
    }

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let deleteRow = async (item_id) => {
        let deleteData = {isDeleted: true}
        api.put("/api/home/delete/" + item_id, deleteData)
            .then(({data: {result_message}}) => {
                if (result_message.type === 'success') {
                    message.success("Kayıt Başarıyla Silindi ...", 2);
                    window.location.reload("/announcements");
                } else {
                    message.error("Kayıt Silinemedi !", 2);
                }
            })
            .catch(err => {
                message.error("Kayıt Silinemedi !", 2);
            })
    }
    const downloadExcel = async () => {
        let option = {};
        let dataTable = [];
        let query = []

        let excelData = await api.get(`/api/news?${qs.stringify({
            page: 1,
            pageSize: 10000,
            query
        })}`, {}).then(({data}) => {
            console.log(data.result)

            return data.result;
        });

        await Promise.all(
            await excelData.news.map(async (item) => {
                let obj = {}

                obj = {
                    'Başlık': item.title ? item.title : '-',
                    'Açıklama': item.content ? item.content.replace( /(<([^>]+)>)/ig, '') : '-',
                    'Yayınlayan': item.Users.name ? item.Users.name + " " + item.Users.surname : '-',
                    'Yayınlama Tarihi': item.createdAt ? moment(item.createdAt).format("DD.MM.YYYY HH:mm")  : '-',
                    'Yayımlanan Kullanıcılar':item.unitList ?  item.unitList : "-",
                    'İl':item.cityList ? item.cityList : "-",
                    //'Yayımlanan Kullanıcılar': item.sendAllUsers ? item.sendAllUsers : item.units ? item.units : '-',
                    'Durumu': item.isDeleted ? "Silindi" : (item.isActive ? "Aktif" : "Pasif"),
                }
                dataTable.push(obj);

            })
        )
        let sheetAreas = ['Başlık', 'Açıklama', 'Yayınlayan', 'Yayınlama Tarihi', 'İl','Yayımlanan Kullanıcılar', 'Durumu'];
        option.fileName = 'Duyurular Raporu'
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: sheetAreas,
                sheetHeader: sheetAreas,
            }
        ];

        let toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    let columns = [
        {
            title: "Başlık",
            dataIndex: 'title',
            key: 'title',
            sorter:(a, b) => a.title.localeCompare(b.title),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: "Açıklama",
            dataIndex: 'content',
            key: 'content',
            sorter:(a, b) => a.content.localeCompare(b.content),
            sortDirections: ['descend', 'ascend'],
            render: (text) => {
                return <div>
                    {text && text.replace( /(<([^>]+)>)/ig, '').length  > 85 ? text.replace( /(<([^>]+)>)/ig, '').substring(0, 85) + " ..." : text.replace( /(<([^>]+)>)/ig, '')}
                </div>
            }
        },
        {
            title: "Yayınlayan",
            dataIndex: 'owner',
            key: 'title',
            sorter:(a, b) => a.Users.name.localeCompare(b.Users.name),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => {
                return <div>
                    {record.Users.name ? record.Users.name + " " + record.Users.surname : "-"}
                </div>
            }
        },
        {
            title:"Yayınlama Tarihi",
            dataIndex: 'createdAt',
            key: 'createdAt',
            sortDirections: ['descend', 'ascend'],
            sorter:(a, b) => a.createdAt.localeCompare(b.createdAt),
            render: (text) => moment(text).format("DD.MM.YYYY HH:mm"),
        },

        // {
        //     title: "Yayımlanan Kullanıcılar",
        //     dataIndex: 'sendAllUsers',
        //     key: 'sendAllUsers',
        //     sorter: (a, b) => a.sendAllUsers - b.sendAllUsers,
        //     sortDirections: ['descend', 'ascend'],
        //     render: (text, record) =>{
        //         return (
        //      record.sendAllUsers==false ?
        //      record.newsToUnit.map((e, i) => (
        //        <div key={i} style={{ marginBottom: 5 }}>
        //          {e.name}
        //        </div>
        //      )) 
        //      :
        //      "Tüm Kullanıcılar"
        //         )
        //      }
        // },
        {
            title: "Durumu",
            dataIndex: 'isActive',
            key: 'isActive',
            //sorter:(a, b) => a.isActive.localeCompare(b.isActive),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => {
                return <div>
                    {record.isActive ? "Aktif" : "Pasif"}
                </div>
            }
        },
        {
            title: "Yayımlanan Kullanıcılar",
            key: 'action',
            align: 'center',
            className: 'editColumn',
            render: (text, record) => (
                <Space size="small">
             <Button type="warning" onClick={() => {
                  setModalData(record)
                  setModalVisible(true)
                  setModalOpen(true)
                            }} icon={<EyeOutlined/>}>{isSmall ? '' : 'Görüntüle'}</Button>
                </Space>
         )},
        {
            title: 'Aksiyonlar',
            key: 'action',
            align: 'right',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    {record.isDeleted ?
                        <Link to={"/announcement/edit/" + record.id}>
                            <Button icon={<FolderViewOutlined/>}>{!isSmall && "Görüntüle"}</Button>
                        </Link> :
                        <Link to={"/announcement/edit/" + record.id}>
                            <Button icon={<EditOutlined/>}>{!isSmall && "Düzenle"}</Button>
                        </Link>
                    }
                    {!record.isDeleted &&
                        <Popconfirm
                            onConfirm={() => deleteRow(record.id)} title={"Bu kayıdı silmek istediğinize emin misiniz?"}
                            okText="Onayla" cancelText={"Vazgeç"}>
                            <Button icon={<DeleteOutlined/>}>{!isSmall && "Sil"}</Button>
                        </Popconfirm>
                    }
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Duyurular</h1>
                </div>
                <div className="list-buttons">
                    <Button type="primary" icon={<EyeOutlined/>} size="large" onClick={() => setDeletedDatas(false)}
                            style={{marginRight: "5px"}}>{!isSmall && "Duyuruların Tümü"}</Button>

                    <Button type="danger" icon={<EyeInvisibleOutlined/>} size="large"
                            onClick={() => setDeletedDatas(true)}
                            style={{marginRight: "5px"}}>{!isSmall && "Silinenleri Görüntüle"}</Button>

                    <Button size="large" onClick={downloadExcel} style={{marginRight: "5px"}}
                            icon={<DownloadOutlined/>}>{!isSmall && 'Duyuruları İndir'}</Button>

                    {!deletedDatas &&
                        <Link to={"/announcement/add"}>
                        <Button type="light" icon={<PlusOutlined/>} size={'large'}
                                style={{marginRight: "5px"}}>{!isSmall && "Yeni Ekle"}</Button>
                    </Link>}
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder="Başlık - Açıklama" onChange={filter}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange} scroll={{x: 'auto'}}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>
            {modalVisible &&
        <>
          <CitiesUnitsPicker 
            setModalVisible={setModalVisible} 
            modalVisible={modalVisible} 
            record={modalData} 
            openModal={modalOpen}
          />
        </>
      }
        </div>
    );
};


export default Announcements;
