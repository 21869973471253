import React, {useEffect, useGlobal, useState} from "reactn";
import {Row, Col, Card, Form, Button, Input, Modal, message, Select, Alert} from 'antd';
import {useHistory} from "react-router-dom";
import {ImageUrl} from "../components";
import api from "../service";
import {useCallback} from "react";

const {Option} = Select;
const Dashboard = () => {
        let history = useHistory()
        let [data, setData] = useState([]);
        let [errors, setErrors] = useState([]);
        let [validationCheck, setValidationCheck] = useState(false)
        let [id, setId] = useState(0)
        let [userId] = useGlobal('user')
        userId = userId.userInfo.id
        const [sizeModalVisible, setIsSizeModalVisible] = useState(false);
        const [identityShowModal, setIsShowIdentityModal] = useState(false)
        const [sizeValue, setSizeValue] = useState([])
        const [coat, setCoat] = useState([])
        const [pants, setPants] = useState([])
        const [greatcoat, setGreatcoat] = useState([])
        const [tshirt, setTshirt] = useState([])
        const [summerShoes, setSummerShoes] = useState([])
        const [winterShoes, setWinterShoes] = useState([])
        const [identityInfo, setIdentityInfo] = useState({})
        const [sizeGet, setSizeGet] = useState([])
        const [sizeValues, setSizeValues] = useState([])

        const handleChange = (value) => {
            console.log(`selected ${value}`);
        };

        const get = async () => {
            await api.get("/api/users/" + userId).then(({data: {result, result_message}}) => {
                setId(result[0].id)
                setData(result[0]);
            });

            await api.get("/api/identity-info/" + userId).then(({data: {result}}) => {
                setIdentityInfo(result[0])
            })

            await api.get(`/api/user-size-get/${userId}`, {}).then(({data}) => {
                if (data.result) setSizeGet(data.result)
                else message.error("Beden Bilgileri Getirilemedi !")
            });

            await api.get("/api/sizeSelection").then(({data: {result}}) => {
                setCoat(result[0].SizeValues.filter(i => i.selected === true))
                setGreatcoat(result[1].SizeValues.filter(i => i.selected === true))
                setPants(result[2].SizeValues.filter(i => i.selected === true))
                setTshirt(result[3].SizeValues.filter(i => i.selected === true))
                setSummerShoes(result[4].SizeValues.filter(i => i.selected === true))
                setWinterShoes(result[5].SizeValues.filter(i => i.selected === true))
                if (result) {
                }
                setSizeValue(result)
            })

        }

        useEffect(() => {
            get()
        }, []);

        let validate = useCallback(() => {
            let errors = {};

            if (data.name == null || data.name.length === 0)
                errors.name = "Zorunlu Alan";
            if (data.phone == null || data.phone.length === 0)
                errors.phone = "Zorunlu Alan";
            if (data.surname == null || data.surname.length === 0)
                errors.surname = "Zorunlu Alan";
            if (data.alternate_phone == null || data.alternate_phone.length === 0)
                errors.alternate_phone = "Zorunlu Alan";


            errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
            errors.hasError = errors.all.length > 0;
            return errors;
        }, [data]);

        useEffect(() => {
            if (validationCheck) setErrors(validate())
        }, [validationCheck, data, validate]);

        let save = async () => {
            setValidationCheck(true)
            let err = validate()
            if (err.hasError) {
                setErrors(err)
                window.scrollTo({top: 20, behavior: 'smooth'});
            } else {
                if (id) {
                    if (data.phone && !data.phone.startsWith("+")) {
                        data.phone = "+" + data.phone
                    }
                    if (data.image_url) data.image_url = data.image_url.url

                    api.put("/api/me/"+id, data).then(({data: {result, result_message}}) => {
                        if (result_message.type === 'success') {
                            message.success("Bilgiler güncellendi", 2);
                            history.push('/adminInfo')
                        } else
                            message.error(result_message.message, 2);
                    })
                }
            }
        };

        const saveSizeInfo = async () => {
            setIsSizeModalVisible(false)
            if (id) {
                api.post("/api/sizeSelection/" + id, {"sizeValues": sizeValues})
                    .then(({data: {result, result_message}}) => {
                        message.success("Bilgiler güncellendi");
                        history.push('/')
                    }).catch((err) => {
                    console.log(err)
                })
            } else {
                api.post("/api/sizeSelection/" + id, {"sizeValues": sizeValues})
                    .then((data) => {
                    }).catch((err) => {
                    console.log(err)
                })
            }
        }
    
        const saveIdentityInfos = async () => {
            setIsShowIdentityModal(false)
            if (id) {
                identityInfo.image1=identityInfo.image1.url ? identityInfo.image1.url : identityInfo.image1
                identityInfo.image2=identityInfo.image2.url ? identityInfo.image2.url : identityInfo.image2
                await api.put("/api/identity-info/" + id, identityInfo)
                    .then(({data: {result, result_message}}) => {
                        message.success("Bilgiler güncellendi");
                        history.push('/')
                    }).catch((err) => {
                        console.log(err)
                    })
            } else {
                identityInfo.image1=identityInfo.image1.url ? identityInfo.image1.url : identityInfo.image1
                identityInfo.image2=identityInfo.image2.url ? identityInfo.image2.url : identityInfo.image2
                await api.post("/api/identity-info/", identityInfo)
                    .then((data) => {
                    }).catch((err) => {
                        console.log(err)
                    })
            }
    
        }

        return (
            <div>
                <div className="list-head">
                    <div className="list-title">
                        <h1>Admin Bilgileri</h1>
                    </div>
                </div>
                <div className="form-wrap">
                <Row direction="row">
                            {sizeModalVisible && (
                                <>
                                    <Modal
                                        title="Ürünler"
                                        visible={sizeModalVisible}
                                        onOk={saveSizeInfo}
                                        onCancel={() => setIsSizeModalVisible(false)}
                                    >
                                        <label style={{fontSize: 'medium'}}>Mont</label>
                                        <Select
                                            defaultValue={ sizeGet ? sizeGet.Mont.length > 0 ? sizeGet.Mont : 'Mont' : 'Mont'}
                                            style={{width: 80}}
                                            onChange={e => 
                                                sizeValue[0].SizeValues.map((item) => {
                                                    if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                                })
                                            }>
                                            {sizeValue[0].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Kaban</label>
                                        <Select
                                            defaultValue={ sizeGet ? sizeGet.Kaban.length > 0 ? sizeGet.Kaban : 'Kaban' : 'Kaban'}
                                            style={{width: 80}}   onChange={e => sizeValue[1].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[1].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Tişört</label>
                                        <Select
                                            defaultValue={ sizeGet ? sizeGet.TShirt.length > 0 ? sizeGet.TShirt : 'T-Shirt' : 'T-Shirt'}
                                            style={{width: 80}}   onChange={e => sizeValue[3].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[3].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Pantolon</label>
                                        <Select
                                            defaultValue={ sizeGet ? sizeGet.Pantolon.length > 0 ? sizeGet.Pantolon : 'Pantolon' : 'Pantolon'}
                                            style={{width: 80}}   onChange={e => sizeValue[2].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[2].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>
                                                    {item.value}
                                                </Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Yazlık Ayakkabı</label>
                                        <Select
                                            defaultValue={ sizeGet ? sizeGet.YazlikAyakkabi.length > 0 ? sizeGet.YazlikAyakkabi : 'Yazlık Ayakkabı': 'Yazlık Ayakkabı' }
                                            style={{width: 80}}   onChange={e => sizeValue[4].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[4].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>
                                                    {item.value}
                                                </Option>
                                            ))}
                                        </Select>
                                        <label style={{fontSize: 'medium', marginTop: '0.25rem'}}>Kışlık Ayakkabı</label>
                                        <Select
                                            defaultValue={ sizeGet ? sizeGet.KislikAyakkabi.length > 0 ? sizeGet.KislikAyakkabi : 'Kışlık Ayakkabı' : 'Kışlık Ayakkabı' }
                                            style={{width: 80}}  onChange={e => sizeValue[5].SizeValues.map((item) => {
                                                if (item.value === e ) setSizeValues([ ...sizeValues, {sizeId: item.id, userId: id}])
                                            })}>
                                            {sizeValue[5].SizeValues.map((item) => (
                                                <Option key={item.id} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                    </Modal>
                                </>
                            )}
                            { identityShowModal  && (
                                <Modal title="Kimlik Belgeleri" visible={identityShowModal}
                                       onOk={saveIdentityInfos} onCancel={() => setIsShowIdentityModal(false)}>
                                    <Alert
                                        message={
                                            'Emniyet Genel Müdürlüğü tarafından verilen güvenlik görevlisi kimlik belgesini yükleyiniz'}
                                        type="info"
                                        showIcon
                                    />
                                    <div className={'modal__show'}>
                                        <ImageUrl
                                            style={{marginTop: '.50rem'}}
                                            record={identityInfo}
                                            setRecord={setIdentityInfo}
                                            name="image1"
                                        />
                                        <ImageUrl
                                            style={{marginTop: '.50rem'}}
                                            record={identityInfo}
                                            setRecord={setIdentityInfo}
                                            name="image2"
                                        />
                                    </div>
                                    <Row direction={'row'}>
                                        <Col
                                            xs={{span: 12}}
                                            md={{span: 12}}
                                            style={{paddingRight: '20px'}}
                                        >
                                            <Input
                                                name="date"
                                                value={identityInfo && identityInfo.effectiveDate}
                                                onChange={(e) =>
                                                    setIdentityInfo({
                                                        ...identityInfo,
                                                        effectiveDate: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col xs={{span: 12}} md={{span: 12}}>
                                            <Input
                                                name="date"
                                                value={identityInfo && identityInfo.organiseDate}
                                                onChange={(e) =>
                                                    setIdentityInfo({
                                                        ...identityInfo,
                                                        organiseDate: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Modal>
                            )}
                        </Row>
                    <Card title={"Düzenle"}>
                        <Form layout="horizontal" size={"large"} onFinish={save}>
                            <Row direction="row">
                                <Col xs={{span: 12}} md={{span: 12}}>
                                    <Form.Item label={"İsim"} required help={errors.name}
                                               validateStatus={errors.name ? 'error' : 'success'}>
                                        <Input name="name" value={data.name}
                                               onChange={e => setData({...data, name: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 12}} md={{span: 12}}>
                                    <Form.Item label={"Soyisim"} required help={errors.surname}
                                               validateStatus={errors.surname ? 'error' : 'success'}>
                                        <Input name="surname" value={data.surname}
                                               onChange={e => setData({...data, surname: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 12}} md={{span: 12}}>
                                    <Form.Item label={"E-mail"} required help={errors.email}
                                               validateStatus={errors.email ? 'error' : 'success'}>
                                        <Input name="name" value={data.email}
                                               onChange={e => setData({...data, email: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 12}} md={{span: 12}}>
                                    <Form.Item label={"Telefon"} required help={errors.phone}
                                               validateStatus={errors.phone ? 'error' : 'success'}>
                                        <Input name="phone" value={data.phone}
                                               onChange={e => setData({...data, phone: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 12}} md={{span: 12}}>
                                    <Form.Item label={"Alternatif Telefon"} required
                                               help={errors.alternate_phone}
                                               validateStatus={errors.alternate_phone ? 'error' : 'success'}>
                                        <Input name="alternative_phone" value={data.alternate_phone}
                                               onChange={e => setData({...data, alternate_phone: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 12}} md={{span: 12}}>
                                    <Form.Item label={"Ofis"}>
                                        <Input name="title" value={data.title}
                                               onChange={e => setData({...data, title: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 12}} md={{span: 12}}>
                                    <Form.Item label={"Platform Os"}>
                                        <Input name="platformOs" value={data.platformOs} disabled={true}
                                               onChange={e => setData({...data, platformOs: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 12}} md={{span: 12}}>
                                    <Form.Item label={"Şifre"}>
                                        <Input type={"password"} name="password"
                                               onChange={e => setData({...data, password: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Beden ölçüleri" validateStatus={errors.size ? 'error' : 'success'}>
                                        <Button onClick={() => setIsSizeModalVisible(true)}>Beden ölçülerini seç</Button>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Kimlik Belgeleri" validateStatus={errors.size ? 'error' : 'success'}>
                                        <Button onClick={() => setIsShowIdentityModal(true)}>Kimlik Belgelerini
                                            ekle</Button>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 10}}>
                                    <Form.Item label="Medya" validateStatus={errors.image ? 'error' : 'success'}>
                                        <ImageUrl record={data} setRecord={setData} name="image_url"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" size="large" block> Kaydet </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    }
;


export default Dashboard;
