import {  Modal } from 'antd';

import React, { useState, useEffect } from 'react';


export default (props) => {
    let { record,openModal, modalVisible, setModalVisible } = props;
    const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState([]);
  const [modalUnits, setModalUnits] = useState([]);
  const [modalCities, setModalCities] = useState([]);


  useEffect(() => {
    console.log(openModal)
    console.log(record)
    if (record && modalVisible==true){
        if(record.sendAllUsers==false){ 
          setModalUnits(record.unitList)
          setModalCities(record.cityList)}
          else{

            setModalUnits("Tüm Kullanıcılar")

          }
    } 
  });

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setModalVisible(false);
  };
console.log(record)
  return (
    <div>
    <>
    <Modal
        title="Gönderilen Şubeler"
        visible={modalVisible}
        className="modal_unit"
        okButtonProps={{ style: { display: 'none' } }}
        confirmLoading={confirmLoading}
        cancelText="Tamam"
        onCancel={handleCancel}
      >
        <p>{modalCities && modalCities }</p>
        <p>{modalUnits}</p>
      </Modal>
    </>
    </div>
  );
};
