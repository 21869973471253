import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, message,InputNumber,Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { ImageUrl } from '../components';
import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";

const OurBankDetail = () => {
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
    let newRecord = {
        name: '',
        lastname: "",
        image: "",
        imageWidth: 0,
        imageHeight: 0,
        order: null,
        part: "",
    }
    let [data, setData] = useState(id ? [] : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(true);
    let [showAlert, setShowAlert] = useState(false);
    let [isSmall,] = useGlobal('isSmall')
    let [validationCheck, setValidationCheck] = useState(false)
    let validate = useCallback(() => {
        let errors = {};

        if (data.image_url == null)
            errors.image_url = "Zorunlu Alan";

        if (data.name == null || data.name.length === 0)
            errors.name = "Zorunlu Alan";

        if (data.surname == null || data.surname.length === 0)
            errors.surname = "Zorunlu Alan";

        if (data.order == null)
            errors.order = "Zorunlu Alan"
        
        if (data.part == null || data.part.length === 0)
            errors.part = "Zorunlu Alan";

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);


    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);

    useEffect(() => {
        setLoading(true)
        get();
    }, [])

    let get = async () => {
        if (id) {
            await api.get("/api/about_bank/" + id).then(({ data: { result } }) => {
                setData(result[0]);
                setLoading(false)
            });
        } else {
            setLoading(false)
        }
    }

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        } else {
            if(showAlert==true){
                message.error("Lütfen farklı bir sıra numarası seçiniz!");
            }
            else{
            if (id) {
                if (data.image_url) {
                    data.type = "image";
                    data.image_height = data.image_url.height || data.image_height
                    data.image_width = data.image_url.width || data.image_width
                    data.image_url = data.image_url.url || data.image_url
                }
                await api.put("/api/about_bank/" + id, data)
                    .then(({ data: { result } }) => {
                        if (result.id) {
                            message.success("Kayıt Başarıyla Güncellendi ...", 2);
                            history.push("/ourBank")
                        } else
                            message.error("Kayıt Güncellenemedi !", 2);
                    })
                    .catch(err => {
                        message.error("Kayıt Güncellenemedi !", 2);
                    })
            } else {
                if (data.image_url) {
                    data.type = "image";
                    data.image_height = data.image_url.height || data.image_height
                    data.image_width = data.image_url.width || data.image_width
                    data.image_url = data.image_url.url || data.image_url
                }
                api.post("/api/about_bank", data).then(({ data: { result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success("Kayıt Başarıyla Eklendi", 2);
                        history.push("/ourBank")
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }                
            }

        }
    };
    let selectOrder = async (v) => {
        setShowAlert(false)
        await api.get("/api/about_bank_order/" +v).then(({ data: { result } }) => {
            if(result && result==true){
                setShowAlert(true)
            }

        });
        setData({ ...data, order: v })

                };
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Bankamız</h1>
                </div>
                <div className="list-buttons">
                    <Link to={"/ourBank"}>
                        <Button type="light" icon={<LeftOutlined />} size={'large'}>{!isSmall && "Geri"}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? "Düzenle" : "Yeni Ekle"} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>
                        <Row direction="row">
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="Resim" required help={errors.image_url}
                                        validateStatus={errors.image_url ? 'error' : 'success'}>
                                        <ImageUrl record={data} setRecord={setData} name="image_url" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <Form.Item label={"İsim"} required help={errors.name}
                                        validateStatus={errors.name ? 'error' : 'success'}>
                                        <Input name="name" value={data.name}
                                            onChange={e => setData({ ...data, name: e.target.value })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <Form.Item label={"Soyisim"} required help={errors.surname}
                                        validateStatus={errors.surname ? 'error' : 'success'}>
                                        <Input name="surname" value={data.surname}
                                            onChange={e => setData({ ...data, surname: e.target.value })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <Form.Item label={"Bölüm"} required help={errors.part}
                                        validateStatus={errors.part ? 'error' : 'success'}>
                                        <Input name="part" value={data.part}
                                            onChange={e => setData({ ...data, part: e.target.value })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label="Sıra" help={errors.order} validateStatus={errors.order ? 'error' : 'success'}>
                                        <InputNumber type="number" value={data.order} min={1} defaultValue={0} style={{ width: 100 }} onChange={selectOrder} />
                                        {showAlert &&
                                            <Alert message="Seçtiğiniz Sıra Numarası Kullanılmaktadır!" banner />
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>


                        </Row>
                        <Row direction="row">
                            <Col xs={24}>
                                <Form.Item>
                                    <Button
                                        style={{ backgroundColor: '#e12518', color: 'white' }} disabled={loading} htmlType="submit" size="large"
                                        block> {"KAYDET"} </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};


export default OurBankDetail;
