import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, Form, Input, Button, DatePicker, Card, message, Radio, Checkbox, Switch, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { CitiesPicker, UnitsPicker } from "../components";
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';



const PushNotificationDetail = () => {
    let params = useParams();
    let history = useHistory();
    let id = params.id !== 'add' ? params.id : false;
    const { TextArea } = Input;
    let [errors, setErrors] = useState([]);
    let [validationCheck, setValidationCheck] = useState(false);
    let [cityCheck, setCityCheck] = useState(false);
    let [unitCheck, setUnitCheck] = useState(false);

    let [visible, setVisible] = useState(false);

    let [userId] = useGlobal('user')

    let newRecord = {
        title: '',
        notificationSendFrequency: 0,
        description: '',
        sendAllUsers: false,
        sendDate: new Date(),
    };
    let [data, setData] = useState(id ? [] : newRecord);
    useEffect(() => {
        if (id) {
            api.get('/api/public-notifications/' + id)
                .then(({ data: { result } }) => {
                    if (result[0]) {
                        if (result[0].notificationSendFrequency == 0 && result[0].completed==!false) {
                            setVisible(true)
                        }
                        if (result[0].PushNotificationToCity) {
                            let cities = [];
                            result[0].PushNotificationToCity.map(z => { cities.push(z.cityId) })
                            result[0].cities = cities
                        }
                        if (result[0].pushNotificationToUnit) {
                            let units = [];
                            result[0].pushNotificationToUnit.map(z => {
                                let unitdata = { code: z.unitcode, name: z.name }
                                units.push(unitdata)
                            })
                            result[0].units = units
                        }
                    }
                    setData(result[0]);
                });
        }

    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        if (data.title === null || data.title.length === 0)
            errors.title = 'Zorunlu Alan';


        if (data.sendDate === null) errors.sendDate = 'Zorunlu Alan';
        if (data.sendAllUsers == false) {
            if (!data.cities || data.cities.length === 0) {
                setCityCheck(true)
            }
            else {
                if (!data.units || data.units.length === 0) {
                    setUnitCheck(true)
                }
            }

        }

        if (data.description == null || data.description.length === 0) {
            errors.description = "Zorunlu Alan";
        }
        if (data.notificationSendFrequency == 1) {
            if (data.postingFrequency == null || data.postingFrequency.length === 0) {
                errors.postingFrequency = "Zorunlu Alan";
            }
        }

        if ((id && userId && userId.userInfo.role === "approver") && (data && data.Users && data.Users.role !== 'approver')) {
            if (data.active == false) {
                if (data.confirmDescription == null || data.confirmDescription.length === 0) {
                    if (userId.userInfo.role === 'approver') {
                        errors.confirmDescription = "Zorunlu Alan";
                    }
                }
            }
        }
        errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => {
        if (validationCheck) setErrors(validate());
    }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true);
        let err = validate();
        if (err.hasError) {
            setErrors(err);
            window.scrollTo({ top: 20, behavior: 'smooth' });
        } else {
            if (id) {
                if (userId && userId.userInfo.role !== 'approver') {
                    data.active = false
                }
                api.put('/api/public-notifications/' + id, data)
                    .then(({ data: { result_message } }) => {
                        if (result_message.type === 'success') {
                            message.success('Güncelleme İşlemi Başarılı', 2);
                            history.push('/notifications');
                        } else message.error('Bildirim Güncellenemedi', 2);
                    });
            } else {
                data.sendAllUsers ? data.sendAllUsers = true : data.sendAllUsers = false
                if (userId && userId.userInfo.role !== "approver") {
                    // data.cities = [];
                    // data.units = [];
                    data.active = false
                    api.post('/api/public-notifications', data)
                        .then(({ data: { result_message } }) => {
                            if (result_message.type === 'success') {
                                message.success('İçerik Başarıyla Kaydedildi', 2);
                                history.push('/notifications');
                            } else {
                                message.error('Bildirim için onay beklenecektir', 2);
                            }
                        });
                } else {
                    data.active = true
                    api.post('/api/public-notifications', data)
                        .then(({ data: { result, result_message } }) => {
                            if (result_message.type === 'success') {
                                message.success('İçerik Başarıyla Kaydedildi', 2);
                                history.push('/notifications');
                            } else {
                                message.error('İçerik oluşturulamadı.', 2);
                            }
                        });
                }
            }
        }
    };
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Bildirimler</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/notifications">
                        <Button type="light" icon={<LeftOutlined />} size="large">
                            {'Geri'}
                        </Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? 'Düzenle' : 'Yeni Ekle'} >
                    <Form layout="horizontal" size={'large'} onFinish={save}>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item
                                    label="Gönderim Sıklığı">
                                    <Radio.Group
                                        disabled={visible}
                                        onChange={(v) => setData({ ...data, notificationSendFrequency: v.target.value })}
                                        value={data.notificationSendFrequency}>
                                        <Radio value={0}>Bir Kere</Radio>
                                        <Radio value={1}>Sürekli</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item
                                    label={'Gönderim Başlama Tarihi'}
                                    required
                                    help={errors.sendDate}
                                    validateStatus={errors.sendDate ? 'error' : 'success'}
                                >
                                    <DatePicker 
                                        placeholder={moment(data.sendDate).format("DD/MM/YYYY HH:mm") ? moment(data.sendDate).format("DD/MM/YYYY HH:mm")  : moment()}
                                        locale={locale}
                                        disabled={visible}
                                        onChange={(v) => setData({ ...data, sendDate: v })}
                                        format="DD/MM/YYYY HH:mm"
                                        showTime={true}
                                    />
                                </Form.Item>
                            </Col>
                            {
                                data.notificationSendFrequency === 1 &&
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item
                                        disabled={visible}
                                        label="Gönderim Sıklığı" required
                                        help={errors.postingFrequency}
                                        validateStatus={errors.postingFrequency ? 'error' : 'success'}>


                                        <Radio.Group
                                            disabled={visible}
                                            onChange={(v) => setData({ ...data, postingFrequency: v.target.value })}
                                            value={data.postingFrequency}>
                                            <Radio disabled={visible} value={0}>Aylık</Radio>
                                            <Radio disabled={visible} value={1}>15 günlük</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            }

                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item
                                    label={'Bildirim Konusu'}
                                    required help={errors.title}
                                    validateStatus={errors.title ? 'error' : 'success'}>
                                    <Input name="title" value={data.title}
                                        disabled={visible}
                                        onChange={(e) =>
                                            setData({ ...data, title: e.target.value })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item
                                    label={'Bildirim Açıklaması'}
                                    required
                                    help={errors.description}
                                    validateStatus={errors.description ? 'error' : 'success'}
                                >
                                    <TextArea
                                        disabled={visible}
                                        name="description"
                                        value={data.description}
                                        onChange={(e) =>
                                            setData({ ...data, description: e.target.value })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Tüm Kullanıcılar" >

                                    <Checkbox disabled={visible} onChange={(e) => setData({ ...data, sendAllUsers: e.target.checked })} checked={data.sendAllUsers}>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            {(id && userId && userId.userInfo.role === "approver") && (data && data.Users && data.Users.role !== 'approver') &&
                                <Row direction="row">
                                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                        <Form.Item label="Bildirimi onayla">
                                            <Switch checked={data.active ? true : false} onChange={(e) => setData({ ...data, active: e })} checkedChildren={"Onay"}
                                                unCheckedChildren={"Red"} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                        <Form.Item label="Onay Açıklaması" help={errors.confirmDescription} validateStatus={errors.confirmDescription ? 'error' : 'success'}>
                                            <TextArea rows={3} name="confirmDescription" value={data.confirmDescription}
                                                onChange={e => setData({ ...data, confirmDescription: e.target.value })} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            }
                            {!data.sendAllUsers &&

                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="İller" required help={errors.cities}>
                                        <CitiesPicker disabled={visible} record={data} setRecord={setData} name="cities" />
                                        {cityCheck &&
                                            <Alert message="İl Seçimi Zorunludur!" banner />
                                        }
                                    </Form.Item>
                                </Col>
                            }
                            {data.cities && !data.sendAllUsers &&
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="Şubeler">
                                        <UnitsPicker disabled={visible} record={data} setRecord={setData} name="units" />
                                        {unitCheck &&
                                            <Alert message="Şube Seçimi Zorunludur!" banner />
                                        }
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        style={{ backgroundColor: '#e12518', color: 'white' }}
                                        htmlType="submit"
                                        size="large" block>
                                        {'Gönder'}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default PushNotificationDetail;
