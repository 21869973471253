import React, {useEffect, useState} from "reactn";
import {Row, Col, Form, Input, Button, Card, message} from 'antd';
import api from '../service/index'
import {useHistory} from 'react-router-dom';
import {useCallback} from "react";
import qs from "qs";

const {TextArea} = Input;

const Education = () => {
    const [data, setData] = useState([]);
    const [id, setId] = useState(0);
    const [validationCheck, setValidationCheck] = useState(false)
    const [errors, setErrors] = useState([])
    const history = useHistory()
    const validate = useCallback(() => {
        let errors = {};
        if (data.title == null || data.title.length === 0)
            errors.title = "Zorunlu Alan";
        if (data.content == null || data.content.length === 0)
            errors.content = "Zorunlu Alan";
        if (data.link == null || data.link.length === 0)
            errors.link = "Zorunlu Alan";
        if (data.subtitle == null || data.subtitle.length === 0)
            errors.subtitle = "Zorunlu Alan";

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    const getData = async () => {
        let query = []
        let _params = {query};
        let restData = await api.get(`/api/education?${qs.stringify(_params)}`, _params)
            .then(({data}) => {
                if (data && data.result) {
                    return data.result.map((item, key) => {
                        item.key = key;
                        return item;
                    })
                }
            })
            .catch(err => {
                console.log(err);
            });
        setId(restData[0].id);
        setData(restData[0]);
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({top: 20, behavior: 'smooth'});
        } else {
            if (id) {
                api.put("/api/education/" + id, data)
                    .then(({data: {result, result_message}}) => {
                        if (result_message.type === 'success') {
                            message.success("Kayıt Başarıyla Güncellendi ...", 2);
                            history.push('/education/')
                        } else
                            message.error("Kayıt Güncellenemedi !", 2);
                    })
                    .catch(err => {
                        message.error("Kayıt Güncellenemedi !", 2);
                    })
            }
        }
    };
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Eğitim</h1>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={"Düzenle"}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 24}}>
                                <Form.Item label="Başlık" required help={errors.title}
                                           validateStatus={errors.title ? 'error' : 'success'}>
                                    <Input name="title" value={data.title}
                                           onChange={e => setData({...data, title: e.target.value})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 24}}>
                                <Form.Item label="Alt Başlık" required help={errors.subtitle}
                                           validateStatus={errors.subtitle ? 'error' : 'success'}>
                                    <Input name="subtitle" value={data.subtitle}
                                           onChange={e => setData({...data, subtitle: e.target.value})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 24}}>
                                <Form.Item label="Açıklama" required help={errors.content}
                                           validateStatus={errors.content ? 'error' : 'success'}>
                                    <TextArea rows={5} name="content" value={data.content}
                                              onChange={e => setData({...data, content: e.target.value})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="Link" required help={errors.link}
                                           validateStatus={errors.link ? 'error' : 'success'}>
                                    <Input name="link" value={data.link}
                                           onChange={e => setData({...data, link: e.target.value})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item>
                                    <Button 
                                        style={{backgroundColor: '#e12518', color: 'white'}} htmlType="submit" size="large"
                                            block> {"KAYDET"} </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </div>
        </div>
    );
};
export default Education