import React, {useEffect, useState, useGlobal} from 'reactn';
import {Table, Space, Button,Modal, Input, Popconfirm,message,Tooltip} from 'antd';
import api from '../service/index';
import {Link} from 'react-router-dom';
import qs from 'qs';
import {
   EditOutlined, DeleteOutlined, PlusOutlined,EyeOutlined,
   Loading3QuartersOutlined, DownloadOutlined,CloseCircleFilled,SearchOutlined
} from '@ant-design/icons';
import ExportJsonExcel from "js-export-excel";
import moment from "moment";
import { CitiesUnitsPicker} from "../components";


const PushNotifications = () => {
   const {Search} = Input;
   let [data, setData] = useState([]);
   let [cancelData, setCancelData] = useState({});

   let [modalData, setModalData] = useState([]);
   let [totalCount, setTotalCount] = useState(0);
   let [selectedRows, setSelectedRows] = useState([]);
   let [search] = useState(false);
   let [loading, setLoading] = useState(false);
   let [isSmall] = useGlobal('isSmall');
   let [unchangedData, setUnchangedData] = useState([])
   const [modalOpen, setModalOpen] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);

   let handleTableChange = async (page, filters, sorter) => {
      get(page, filters, sorter);
   };

   let get = async (page, filters, sorter) => {
      if (page) {
         page = {page: page.current, pageSize: page.pageSize};
      } else {
         page = {page: 1, pageSize: 10};
      }
      let shortString = '-createdAt';

      if (sorter) {
         if (Object.keys(sorter).length) {
            shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
         }
      }

      let _params = {sort: shortString, ...page};

      if (search.length > 2) {
         _params['search'] = search.toLowerCase();
         _params['searchFields'] = 'title';
      }
      let unitList=[]
      let restData = await api
         .get(`/api/public-notifications?${qs.stringify(_params)}`, {_params})
         .then(({data}) => {
            if (data.result) {
               setLoading(false);
               setTotalCount(data.result.total);
               return data.result.map((item, key) => {
                  // item.sendAllUsers==false ?
                  // item.pushNotificationToUnit.map((e, i) => (
                  //    unitList.push(e.name)
                  // )) 
                  // :
                  // "Tüm Kullanıcılar"
                  // item.unitList=unitList;
                  item.key = key;
                  return item;
               });
            }
         });
      setData(restData);
      setUnchangedData(restData)
   };

   useEffect(() => {
      setLoading(true);
      get();
   }, []);

   useEffect(() => {
      get();
   }, [search]);

   let rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
         setSelectedRows(selectedRows);
      },
      onSelect: (record, selected, selectedRows) => {
         setSelectedRows(selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
         setSelectedRows(selectedRows);
      },
   };

   const filter = (e) => {
      const val = e.target.value.toLocaleLowerCase();
      const tempList = Object.assign([], unchangedData);
      if (val === '' || !val) {
         setData(unchangedData)
         return;
      }
      let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
      setData(filteredData)
   }

   const isContainsFilterValue = (t, val) => {
      const isContains2 = t.title == null ? false : t.title.toLowerCase().indexOf(val) !== -1;
      const isContains3 = t.description == null ? false : t.description.toLowerCase().indexOf(val) !== -1;
      return isContains2 || isContains3
   }

   let deleteRow = async (item_id) => {
      // await api.delete(`/api/public-notifications/${item_id}`, ({data}) => {
      // });
      // let newData = data.filter((el) => el.id !== item_id);
      // setData(newData);

      let deleteData = {isDeleted: true}
      api.put("/api/public-notifications-delete/" + item_id, deleteData)
          .then(({data: {result_message}}) => {
              if (result_message.type === 'success') {
                  message.success("Kayıt Başarıyla Silindi ...", 2);
                  window.location.reload("/announcements");
              } else {
                  message.error("Kayıt Silinemedi !", 2);
              }
          })
          .catch(err => {
              message.error("Kayıt Silinemedi !", 2);
          })
   };
   let cancel = async (record) => {
      record.completed=true
      record.active=false
      api.put('/api/cancel_public_notification/' + record.id, record)
                    .then(({ data: { result_message } }) => {
                        if (result_message.type === 'success') {
                            message.success('Bildirim başarılı bir şekilde iptal edildi.', 2);
                            window.location.reload(false);
                        } else message.error('Beklenmedik bir sorun oluştu.', 2);
                    });
   };

   let deleteSelectedRows = async () => {
       selectedRows.forEach((item) => {
         let deleteData = {isDeleted: true}
      api.put("/api/public-notifications-delete/" + item.id, deleteData)
          .then(({data: {result_message}}) => {
              if (result_message.type === 'success') {
                  message.success("Kayıt Başarıyla Silindi ...", 2);
                  window.location.reload("/announcements");
              } else {
                  message.error("Kayıt Silinemedi !", 2);
              }
          })
          .catch(err => {
              message.error("Kayıt Silinemedi!", 2);
          })
         let newData = data.filter((el) => el.id !== item.id);
         setData(newData);
         get();
      });
      window.location.reload(false);
   };

   let columns = [
      {
         title: 'Konu',
         dataIndex: 'title',
         key: 'title',
         sorter:(a, b) => a.title.localeCompare(b.title),
         sortDirections: ['descend', 'ascend'],
         render: (text) => {
            return <div>
               {text.length > 85 ? text.substring(0, 40) + " ..." : text}
            </div>
         }
      },
      {
         title: 'Açıklama',
         dataIndex: 'description',
         key: 'description',
         sorter:(a, b) => a.description.localeCompare(b.description),
         sortDirections: ['descend', 'ascend'],
         render: (text) => {
            return <div>
               {text.length > 85 ? text.substring(0, 40) + " ..." : text}
            </div>
         }
      },
      {
         title: "Sıklık",
         dataIndex: 'notificationSendFrequency',
         key: 'notificationSendFrequency',
         //sorter:(a, b) => a.notificationSendFrequency.localeCompare(b.notificationSendFrequency),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return (
               record.notificationSendFrequency === 1 ? "Sürekli" : "Bir kere"
            )

         }
      },
      {
         title: "Gönderim Yapan",
         dataIndex: 'sender',
         key: 'sender',
         sorter:(a, b) => a.Users.name.localeCompare(b.Users.name),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return <div>
               {record.Users.name ? record.Users.name + " " + record.Users.surname : "-"}
            </div>
         }
      },
      {
         title: "Gönderim Sıklığı",
         dataIndex: 'postingFrequency',
         key: 'postingFrequency',
         //sorter:(a, b) => a.postingFrequency.localeCompare(b.postingFrequency),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return (
               record.notificationSendFrequency===0 ? "" : (record.postingFrequency === 0 ? "Aylık" : "15 günlük")
            )

         }
      },
      {
         title: "Gönderim Tarihi",
         dataIndex: 'sendDate',
         key: 'sendDate',
         sorter:(a, b) => a.sendDate.localeCompare(b.sendDate),
         sortDirections: ['descend', 'ascend'],
         render: (text,record) => {
            return (
                moment(record.sendDate).format('DD.MM.YYYY HH:mm')
            )

         }
      },
      {
         title: "Durumu",
         dataIndex: 'active',
         key: 'active',
         //sorter:(a, b) => a.active.localeCompare(b.active),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return (
               record.active === true ? "Aktif" : "Pasif"
            )

         }
      },
      {
         title: "Gönderilen Şube/Birim",
         key: 'action',
         align: 'center',
         className: 'editColumn',
         render: (text, record) => (
            <Space size="small">
         <Button type="warning" onClick={() => {
              setModalData(record)
              setModalVisible(true)
              setModalOpen(true)
                        }} icon={<EyeOutlined/>}>{isSmall ? '' : 'Görüntüle'}</Button>
            </Space>
      )},
      {
         title: 'Aksiyonlar',
         key: 'action',
         className: 'editColumn',
         width: 150,
         render: (text, record) => (
            <Space size="small">
                     <Tooltip title="Düzenle">
                     <Link to={'/notification/edit/' + record.id}>
                  <Button icon={<EditOutlined/>}>{}</Button>
               </Link>
                     </Tooltip>
                     <Tooltip title="Sil">
               <Popconfirm
                  onConfirm={() => deleteRow(record.id)}
                  title={'Silmek İstediğinizden Emin Misiniz ?'}
                  okText={'Onayla'}
                  cancelText={'Vazgeç'}
               >
                  <Button type="danger" icon={<DeleteOutlined/>}>
                     {}
                  </Button>
               </Popconfirm>
               </Tooltip>

               {record.notificationSendFrequency == 1 && record.completed==false && record.active==true &&
                                    <Tooltip title="İptal">

               <Button type="warning" onClick={() => {
              cancel(record)
                        }} icon={<CloseCircleFilled/>}>{}</Button>
                                       </Tooltip>

                        }

            </Space>
         ),
      },
   ];
   const downloadExcel = async () => {
      let option = {};
      let dataTable = [];
      let query = []

      let excelData = await api.get(`/api/public-notifications/excel?${qs.stringify({
         page: 1, pageSize: 10000, query
      })}`, {})
         .then(({data}) => {
            return data.result;
         });
      await Promise.all(
         await excelData.map(async (item) => {
            let obj = {}

            obj = {
               'Konu': item.title ? item.title : "-",
               'Açıklama': item.description ? item.description : '-',
               'Sıklık': item.notificationSendFrequency === 1 ? "Sürekli" : "Bir kere",
               'Gönderimi Yapan': item.Users.name ? item.Users.name + " " + item.Users.surname : '-',
               'Gönderim Sıklığı':item.notificationSendFrequency===0 ? "Bir kere" : (item.postingFrequency === 0 ? "Aylık" : "15 günlük"),
               'İl':item.cityList ? item.cityList : "-",
               'Şube-Birim': item.unitList ?  item.unitList : "-",
               'Durumu': item.active === true ? 'Aktif' : 'Pasif',
               'Gönderim/Bakım Tarihi':  moment(item.sendDate).format('DD.MM.YYYY HH:mm') ? moment(item.sendDate).format('DD.MM.YYYY HH:mm') : '-',
               'Silindi':item.isDeleted ? "evet" : "hayır"
            }
            dataTable.push(obj);
         })
      )
      let sheetAreas = ['Konu', 'Açıklama', 'Sıklık', 'Gönderimi Yapan', 'Gönderim Sıklığı','İl', 'Şube-Birim', 'Durumu', 'Gönderim/Bakım Tarihi','Silindi'];
      option.fileName = 'Bildirimler Raporu'
      option.datas = [
         {
            sheetData: dataTable,
            sheetName: 'sheet',
            sheetFilter: sheetAreas,
            sheetHeader: sheetAreas,
         }
      ];

      let toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
   }

   return (
      <div>
         <div className="list-head">
            <div className="list-title">
               <h1>Bildirimler</h1>
            </div>
            <div className="list-buttons">
               {!isSmall &&
                  <Button icon={<DownloadOutlined/>} size="large" onClick={downloadExcel}
                          style={{marginRight: "5px"}}>{"Bildirimler Raporu"}</Button>
               }
               {!isSmall && (
                  <Button
                     type="danger"
                     icon={<DeleteOutlined/>}
                     size="large"
                     onClick={deleteSelectedRows}
                     style={{marginRight: '5px'}}
                  >
                     Seçilenleri Sil
                  </Button>
               )}
               <Link to="/notification/add">
                  <Button type="light" icon={<PlusOutlined/>} size="large">
                     {isSmall ? '' : 'Yeni Ekle'}
                  </Button>
               </Link>
            </div>
         </div>

         <div className="table-wrap">
            <Search
               placeholder={'Arama Bildirimi'}
               onChange={filter}
               // onSearch={(v) => {
               //   setSearch(v);
               //   get();
               // }}
            />
            <Table
               dataSource={data}
               columns={columns}
               loading={{
                  spinning: loading,
                  indicator: <Loading3QuartersOutlined spin/>,
                  size: 'large',
               }}
               onChange={handleTableChange}
               pagination={{
                  total: totalCount,
               }}
               rowSelection={{...rowSelection}}
            />
         </div>
         {modalVisible &&
        <>
          <CitiesUnitsPicker 
            setModalVisible={setModalVisible} 
            modalVisible={modalVisible} 
            record={modalData} 
            openModal={modalOpen}
          />
        </>
      }
      </div>
   );
};

export default PushNotifications;
