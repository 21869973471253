export {default as Dashboard} from './Dashboard';

export {default as NotFoundPage} from './404';

export {default as Attendee} from './Attendee';
export {default as AttendeeDetail} from './AttendeeDetail';

export {default as Announcements} from './Announcements';
export {default as AnnouncementDetail} from './AnnouncementDetail';

export {default as ControlNotifications} from './ControlNotifications'
export {default as ControlNotificationDetail} from './ControlNotificationDetail'

export {default as OurBank} from './OurBank';
export {default as OurBankDetail} from './OurBankDetail';

export {default as Education} from './Education';

export {default as Contact} from './Contact'

export {default as PushNotification} from './PushNotification'
export {default as PushNotificationDetail} from './PushNotificationDetail'