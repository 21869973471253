import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import 'react-quill/dist/quill.snow.css';
import './styles/style.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import tr_TR from 'antd/lib/locale/tr_TR'

ReactDOM.render( <ConfigProvider locale={tr_TR}><App /></ConfigProvider>, document.getElementById('root'));
serviceWorker.unregister();
