import React, { useEffect } from 'react';
import { setGlobal, useGlobal } from "reactn";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './pages/Login';
import Admin from './Admin';
import axios from 'axios'
import Cookies from 'js-cookie';

axios.interceptors.response.use(result => {
    return result
}, (err) => {
    if (err.response && err.response.status === 401) {
        setGlobal({ token: null })
    }
    return Promise.reject(err)
})

setGlobal({
    isSmall: window.innerWidth <= 1024,
    collapsed: false,
    token: Cookies.get("token"),
    user: localStorage.getItem("user") === undefined ? {} : JSON.parse(localStorage.getItem("user")),
});

const App = () => {
    let [, setToken] = useGlobal('token');
    let [isSmall, setIsSmall] = useGlobal('isSmall')
    let [, setCollapsed] = useGlobal('collapsed');

    useEffect(() => {
        window.onresize = () => {
            setIsSmall(window.innerWidth < 1024)
        }
    }, [setIsSmall, setToken])
    useEffect(() => {
        if (isSmall)
            setCollapsed(true)
        else
            setCollapsed(false)
    }, [isSmall, setCollapsed])


    return (
        <Router>
            <Switch>
                <Route path="/health/ocp" />
                <Route path="/login" component={Login} />
                <Route path="/" component={Admin} />
            </Switch>
        </Router>
    );
};


export default App;
