import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm, message, Spin} from 'antd';
import api from '../service/index'
import qs from 'qs';
import moment from "moment";
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    Loading3QuartersOutlined,
    DownloadOutlined
} from "@ant-design/icons";
import {Link} from 'react-router-dom';
import ExportJsonExcel from 'js-export-excel';
import tr_TR from 'antd/es/locale/tr_TR';


const Attendee = () => {
    const {Search} = Input;
    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let [unchangedData, setUnchangedData] = useState([])
    let [isSmall] = useGlobal('isSmall')
    let query = []
    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        page = {page: 1, pageSize: Number.MAX_VALUE}
        let shortString = 'name';
        setLoading(true)
        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};
        let restData = await api.get(`/api/users?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                return data.result.map((item, key) => {
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
        setLoading(false)
        setUnchangedData(restData)
    }

    const downloadExcel = async () => {
        let option = {};
        let dataTable = [];
        let query = []

        let excelData = await api.get(`/api/users?${qs.stringify({page: 1, pageSize: 10000, query})}`, {}).then(({data}) => {
            return data.result;
        });

        await Promise.all(
        await excelData.map( async (item) => {
            let obj={}

            const imageUrls = await api.get(`/api/identity-info/${item.id}?${qs.stringify({page: 1, pageSize: 10000, query})}`)
            let sizeData = await api.get(`/api/user-size-get/${item.id}?${qs.stringify({page: 1, pageSize: 10000, query})}`, {}).then(({data}) => {
                return data.result;
            });
            let role = "";
            if (item.role ==="approver"){
                role = "Onaycı"
            }
            else if (item.role ==="contributer"){
                role = "Girişçi"
            }
            else if (item.role ==="user"){
                role = "Kullanıcı"
            }
                obj = {
                    'Ad': item.name ? item.name : '-y',
                    'Soyad': item.surname ? item.surname : '-',
                    'Telefon': item.phone ? item.phone : '-',
                    'Alternatif Telefon': item.alternate_phone ? item.alternate_phone : '-',
                    'Role': item.role ? role : '-',
                    'Email': item.email ? item.email : '-',
                    'Mont':  sizeData.Mont ? sizeData.Mont : '-',
                    'Kaban': sizeData.Kaban ? sizeData.Kaban : '-',
                    'Pantolon': sizeData.Pantolon ? sizeData.Pantolon : '-',
                    'Tshirt': sizeData.TShirt ? sizeData.TShirt : '-',
                    'Yazlik Ayakkabi': sizeData.YazlikAyakkabi ? sizeData.YazlikAyakkabi : '-',
                    'Kislik Ayakkabi': sizeData.KislikAyakkabi ? sizeData.KislikAyakkabi : "-",
                    "Düzenlenme tarihi": imageUrls.data.result[0] && imageUrls.data.result[0].organiseDate ? imageUrls.data.result[0].organiseDate : '-',
                    'Tarih': moment(item.createdAt).format('DD.MM.YYYY HH:mm') ? moment(item.createdAt).format('DD.MM.YYYY HH:mm') : '-',
                    "KimlikOnYuz": imageUrls.data.result[0] && imageUrls.data.result[0].image1 ? imageUrls.data.result[0].image1 : '-',
                    "KimlikArkaYuz": imageUrls.data.result[0] && imageUrls.data.result[0].image2 ? imageUrls.data.result[0].image2 : '-',
                    "Geçerlilik tarihi": imageUrls.data.result[0] && imageUrls.data.result[0].effectiveDate ? imageUrls.data.result[0].effectiveDate : '-',
                }
            dataTable.push(obj);

        }) 
        )
        let sheetAreas = ['Ad', 'Soyad', 'Email', 'Telefon','Alternatif Telefon', 'Role', 'Mont', 'Kaban', 'Pantolon', 'Tshirt', 'Yazlik Ayakkabi', 'Kislik Ayakkabi', 'Tarih',"Geçerlilik tarihi", "Düzenlenme tarihi", 'KimlikOnYuz', 'KimlikArkaYuz'];
        option.fileName = 'Kullanıcılar Raporu'
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: sheetAreas,
                sheetHeader: sheetAreas,
            }
        ];

        let toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }
    let getReport = async () => {
        try {
            // Loader'ı görüntülemek için Spin bileşenini kullanabilirsiniz
            setLoading(true);
            const loader = message.loading('Rapor indiriliyor...', 0);
        
            let result = await api.get('/api/usersDownloadExcel');
            const file = new Blob(["\ufeff", result.data]);
            let _url = window.URL.createObjectURL(file);
            let a = document.createElement('a');
            a.href = _url;
            a.download = `kullanicilar_report.xls`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        
            // Loader'ı kaldırın
            loader();
            setLoading(false);
          } catch (error) {
            console.error(error);
            message.error('Rapor indirme hatası');
          }
        // let result = await api.get('/api/usersDownloadExcel')
        // const file = new Blob(["\ufeff", result.data]);
        // let _url = window.URL.createObjectURL(file);
        // let a = document.createElement('a');
        // a.href = _url;
        // a.download = `kullanicilar_report.xls`;
        // document.body.appendChild(a);
        // a.click();
        // a.remove();
    }

    let deleteRow = async (item_id) => {
        await api.delete(`/api/users/${item_id}`, ({data}) => {
        })
            .catch(err => {
                message.error("Kullanıcı Silinemedi !")
            })
        let newData = data.filter(el => el.id !== item_id);
        setData(newData);
    }

    useEffect(() => {
        setLoading(true)
        get();
    }, [])

    const filter = (e) => {
        const val = e.target.value.toLocaleLowerCase();
        const tempList = Object.assign([], unchangedData);
        if (val === '' || !val) {
            setData(unchangedData)
            return;
        }
        let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
        setData(filteredData)
    }

    const isContainsFilterValue = (t, val) => {
        const isContains2 = t.phone == null ? false : t.phone.toLowerCase().indexOf(val) !== -1;
        const isContains3 = t.surname == null ? false : t.surname.toLowerCase().indexOf(val) !== -1;
        const isContains4 = t.name == null ? false : t.name.toLowerCase().indexOf(val) !== -1;
        const isContains5 = t.email == null ? false : t.email.toLowerCase().indexOf(val) !== -1;
        return isContains2 || isContains3 || isContains4 || isContains5;
    }

    let columns = [
        {
            title: 'İsim',
            dataIndex: 'name',
            key: 'name',
            sorter:(a, b) => a.name.localeCompare(b.name),
            sortDirections: ['descend', 'ascend']
        }, {
            title: 'Soyisim',
            dataIndex: 'surname',
            key: 'surname',
            sorter:(a, b) => a.surname.localeCompare(b.surname),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Sicil No',
            dataIndex: 'registrationNo',
            key: 'registrationNo',
            sorter:(a, b) => a.registrationNo.localeCompare(b.registrationNo),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter:(a, b) => a.email.localeCompare(b.email),
            sortDirections: ['descend', 'ascend']
        }, {
            title: 'Kurumsal Telefon',
            dataIndex: 'phone',
            key: 'phone',
            sorter:(a, b) => a.phone.localeCompare(b.phone),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Çalıştığı İl',
            dataIndex: 'city',
            key: 'city',
            sorter:(a, b) => a.city.localeCompare(b.city),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Aksiyonlar',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/attendees/edit/" + record.id}><Button icon={<EditOutlined/>}>Düzenle</Button></Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record.id)} title="Silmeyi Onaylıyor musunuz?"
                        okText="Onayla" cancelText="Vazgeç">
                        <Button type="danger" icon={<DeleteOutlined/>}>Sil</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Kullanıcılar</h1>
                </div>
                <div className="list-buttons">
                    {/* <Button size="large" onClick={downloadExcel} style={{marginRight: "5px"}}
                            icon={<DownloadOutlined/>}>{!isSmall && 'Kullanıcılar Raporu'}</Button> */}
                    <Button type="primary" icon={<DownloadOutlined/>} onClick={ getReport}>
                        {"Kullanıcı Raporu"}
                    </Button>
                    {/* <Link to="/attendees/add" style={{marginRight: "5px"}}>
                        <Button type="light" icon={<PlusOutlined/>} size="large">{!isSmall && "Yeni Ekle"}</Button>
                    </Link> */}
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder="İsim - Soyisim - Email - Telefon - Ara" onChange={filter}/>
                <Table  dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           // total: totalCount
                       }}
                    //rowSelection={!isSmall && {...rowSelection}}
                />
            </div>
        </div>
    );
};


export default Attendee;
