import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import api from '../service/index';

export default (props) => {
    let { record, setRecord, name, disabled } = props;
    let [units, setUnits] = useState([]);
    let [unitsAll, setUnitsAll] = useState(true)
    let [all, setAll] = useState(true)

    useEffect(() => {
        if (record.cities) {
            api.get('/api/get-units/' + record.cities.join(',').trim())
                .then(({ data: { result } }) => {
                    setUnits(result.data);
                });
        }
        if((record && record.units && record.units.length>0  &&  record.units.find(a=>a.code=='all_units'))){
            setAll(false)
        }
        if((record && record.units && record.units.length>0  &&  record.units.find(a=>a.code!='all_units'))){
            setUnitsAll(false)
        }
    }, [record.cities]);
    let selectedUnits = (v) => {
        setUnitsAll(true)
        setAll(true)
        let unit = []
        if (v[0] == 'all_units') {
            let a = {
                code: "all_units",
                name: 'TÜMÜ'
            }
            unit.push(a)
            setUnitsAll(true)
            setAll(false)


        }
        else {
            unit = v.map((x) => {
                if (unit) {
                    setUnitsAll(false)

                    let selectedunitscode = units.find((e) => e.code == x);
                    let selectedunitsname = units.find((e) => e.name == x);

                    if (selectedunitscode) {
                        return {
                            code: selectedunitscode.code,
                            name: selectedunitscode.name
                        }
                    }
                    if (selectedunitsname) {
                        return {
                            code: selectedunitsname.code,
                            name: selectedunitsname.name
                        }
                    }

                }

            });
        }
        setRecord({ ...record, [name]: unit });
    };
    return (
        <Select
            disabled={disabled}
            defaultValue={record[name] ? record[name].map((e) => e ? e.name : '') : []}
            mode="multiple"
            placeholder={"Şubeleri Seçin"}
            onChange={selectedUnits}
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {unitsAll == true && <Select.Option key="all_units" value="all_units">
                TÜMÜ
            </Select.Option>}

            {(units && all == true) &&
                units.map((b) => (
                    <Select.Option key={b.code} value={b.code}>
                        {b.name}
                    </Select.Option>
                ))}
        </Select>
    );
};