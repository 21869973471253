import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {
   DeleteOutlined,
   Loading3QuartersOutlined,
   DownloadOutlined, FolderViewOutlined
} from "@ant-design/icons";
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';

const ControlNotifications = () => {
   const {Search} = Input;
   let query = []

   let [data, setData] = useState([]);
   let [totalCount, setTotalCount] = useState(0)
   let [selectedRows, setSelectedRows] = useState([])
   let [unchangedData, setUnchangedData] = useState([])
   let [loading, setLoading] = useState(false)
   let [isSmall,] = useGlobal('isSmall')


   let handleTableChange = async (page, filters, sorter) => {
      get(page, filters, sorter);
   }

   let get = async (page, filters, sorter) => {
      if (page) {
         page = {page: page.current, pageSize: page.pageSize}
      } else {
         page = {page: 1, pageSize: 10};
      }
      let shortString = 'order';

      if (sorter) {
         if (Object.keys(sorter).length) {
            shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
         }
      }

      let _params = {sort: shortString, ...page, query};

      let restData = await api.get(`/api/control-notifications?${qs.stringify(_params)}`, _params)
         .then(({data}) => {
            if (data && data.result) {
               setTotalCount(data.result.total);
               setLoading(false)
               return data.result.map((item, key) => {
                  item.key = key;
                  return item;
               })
            }
         })
         .catch(err => {
            console.log(err);
         });
      setData(restData);
      setUnchangedData(restData)
   }

   const filter = (e) => {
      const val = e.target.value.toLocaleLowerCase();
      const tempList = Object.assign([], unchangedData);
      if (val === '' || !val) {
         setData(unchangedData)
         return;
      }
      let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
      setData(filteredData)
   }

   const isContainsFilterValue = (t, val) => {
      const isContains2 = t.description == null ? false : t.description.toLowerCase().indexOf(val) !== -1;
      const isContains3 = t.createdAt == null ? false : t.createdAt.toLowerCase().indexOf(val) !== -1;
      const isContains0 = !t.Users?  false : !t.Users.name?false:t.Users.name.toLowerCase().indexOf(val) !== -1;
      const isContains1 = !t.Users?  false : !t.Users.surname?false:t.Users.surname.toLowerCase().indexOf(val) !== -1;
      const isContains4 = t.createdAt == null ? false : val.startsWith(moment(t.createdAt).format("DD-MM-YYYY HH:mm"));
      let isContain5;
      let isContain6;
      if(val.toLowerCase().indexOf('ysc')!=-1){
         isContain5=t.controlType == null ? false : t.controlType!==0;
      }
      if(val.toLowerCase().indexOf('silah')!=-1){
         isContain6=t.controlType == null ? false : t.controlType!==1;
 
      }
      return isContains0 || isContains1 || isContains2 || isContains3 || isContains4 || isContain5 || isContain6;
   }

   useEffect(() => {
      setLoading(true)
      get();
   }, [])

   let rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
         setSelectedRows(selectedRows);
      }
   };

   const downloadExcel = async () => {
      let option = {};
      let dataTable = [];
      let query = []

      let excelData = await api.get(`/api/control-notifications?${qs.stringify({
         page: 1, pageSize: 10000, query
      })}`, {})
         .then(({data}) => {
            return data.result;
         });
      await Promise.all(
         await excelData.map(async (item) => {
            let obj = {}

            obj = {
               'Karne Tipi': item.controlType === 0 ? 'Silah Bakımı' : 'YSC Aylık Kontrol',
               'Şube-Birim': item.Users.branchName ? item.Users.branchName : '',
               'Durumu': item.isControl === true ? 'Var' : 'Yok',
               'Açıklama': item.description ? item.description : '-',
               'Bildirim/Bakım Tarihi':  moment(item.createdAt).format('DD.MM.YYYY HH:mm') ? moment(item.createdAt).format('DD.MM.YYYY HH:mm') : '-',
               'Bildirimi Yapan': item.Users.name ? item.Users.name + " " + item.Users.surname : '-',
               'Bildirim Sahibi Sicil No':item.Users.registrationNo ? item.Users.registrationNo : '-',
               'Bildirim Sahibi Çalıştığı İl':item.Users.city ? item.Users.city :'-'
            }
            dataTable.push(obj);

         })
      )
      let sheetAreas = ['Karne Tipi', 'Şube-Birim', 'Durumu', 'Açıklama', 'Bildirim/Bakım Tarihi', 'Bildirimi Yapan','Bildirim Sahibi Sicil No','Bildirim Sahibi Çalıştığı İl'];
      option.fileName = 'Bildirimler Raporu'
      option.datas = [
         {
            sheetData: dataTable,
            sheetName: 'sheet',
            sheetFilter: sheetAreas,
            sheetHeader: sheetAreas,
         }
      ];

      let toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
   }

   let downloadImage = (imageUrl) => {
      // imageUrl = _download ? _download : record.image_url ? record.image_url : record.image ? record.image : record.thumb
      if (!imageUrl.length) return;
      fetch(imageUrl, {
         method: "GET",
         headers: {},
      })
         .then((response) => {
            let extension = imageUrl.split(".").reverse()[0]
            response.arrayBuffer().then(function (buffer) {
               const url = window.URL.createObjectURL(new Blob([buffer]));
               const link = document.createElement("a");
               link.href = url;
               link.setAttribute(
                  "download",
                  `image.${extension}`
               );
               document.body.appendChild(link);
               link.click();
            });
         })
         .catch(() => {
            console.log("Fotoğraf indirilirken bir hata oluştu")
         });
   };


   let columns = [
      {
         title: "Karne Tipi",
         dataIndex: 'controlType',
         key: 'controlType',
         //sorter:(a, b) =>(isNaN(a.notificationType) && isNaN(b.notificationType) ? (a.notificationType || '').localeCompare(b.notificationType || '') : a.notificationType - b.notificationType),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            if (record.controlType === 0) return <span>Silah Bakımı</span>
            else if (record.controlType === 1) return <span>YSC Aylık Kontrol</span>
         }
      },
      {
         title: "Şube-Birim",
         dataIndex: 'controlUnit',
         key: 'controlUnit',
         sorter:(a, b) => a.controlUnit.localeCompare(b.controlUnit),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return <div>
               {record.units > 0 ? record.units.map((item, key) => {
                  return <div key={key}>{item.name}</div>
               }) : record.sendAllUsers === true ? "Tüm Kullanıcılar" : "Şube/Birim Bazlı detay sayfasında listeleniyor"}
            </div>
         }
      },
      {
         title: "Var-Yok",
         dataIndex: 'isControl',
         key: 'isControl',
         //sorter:(a, b) => a.isControl.localeCompare(b.isControl),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return <div>
               {record.isControl ? "Var" : "Yok"}
            </div>
         }
      },
      {
         title: "Açıklama",
         dataIndex: 'description',
         key: 'description',
         sorter:(a, b) => a.description.localeCompare(b.description),
         sortDirections: ['descend', 'ascend'],
         render: (text) => {
            return <div>
               {text.length > 85 ? text.substring(0, 85) + " ..." : text}
            </div>
         }
      },
      {
         title: "Bildirim/Bakım Tarihi",
         dataIndex: 'createdAt',
         key: 'createdAt',
         sorter:(a, b) => a.createdAt.localeCompare(b.createdAt),
         sortDirections: ['descend', 'ascend'],
         render: (text) => {
            return <div>
               {moment(text).format("DD-MM-YYYY-HH:mm")}
            </div>
         }
      },
      {
         title: "Bildirimi Yapan",
         dataIndex: 'notifier',
         key: 'notifier',
         sorter:(a, b) => a.Users.name.localeCompare(b.Users.name),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return (<div>
               {record.Users.name ? record.Users.name + " " + record.Users.surname : "-"}
            </div>)
         }
      },
      {
         title: "Bildirim Sahibi Sicil No",
         dataIndex: 'notifier',
         key: 'notifier',
         sorter:(a, b) => a.Users.registrationNo.localeCompare(b.Users.registrationNo),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return <div>
               {record.Users.registrationNo ? record.Users.registrationNo : "-"}
            </div>
         }
      },
      {
         title: "Bildirim Sahibi Çalıştığı İl",
         dataIndex: 'notifier',
         key: 'notifier',
         sorter:(a, b) => a.Users.city.localeCompare(b.Users.city),
         sortDirections: ['descend', 'ascend'],
         render: (text, record) => {
            return <div>
               {record.Users.city ? record.Users.city : "-"}
            </div>
         }
      },
      {
         title: 'Aksiyonlar',
         key: 'action',
         align: 'right',
         className: 'editColumn',
         width: 150,
         render: (text, record) => (
            <Space size="small">
               {record.controlType === 1 &&
                  <Button icon={<DownloadOutlined/>} onClick={()=>downloadImage(record.image)}>
                     {!isSmall && "İndir"}
                  </Button>
               }
               <Link to={"/controlNotification/edit/" + record.id}>
                  <Button icon={<FolderViewOutlined/>}>
                     {!isSmall && "Görüntüle"}
                  </Button>
               </Link>
            </Space>
         ),
      },
   ];

   return (
      <div>
         <div className="list-head">
            <div className="list-title">
               <h1>Kontrol Bildirimleri</h1>
            </div>
            <div className="list-buttons">
               {!isSmall &&
                  <Button icon={<DownloadOutlined/>} size="large" onClick={downloadExcel}
                          style={{marginRight: "5px"}}>{"Kontrol Bildirimleri Raporu"}</Button>
               }
               {/*<Link to={"/controlNotification/add"}>*/}
               {/*    <Button type="light" icon={<PlusOutlined/>} size={'large'}*/}
               {/*            style={{marginRight: "5px"}}>{!isSmall && "Yeni Ekle"}</Button>*/}
               {/*</Link>*/}
            </div>
         </div>

         <div className="table-wrap">
            <Search placeholder="Ara" onChange={filter}/>
            <Table dataSource={data} columns={columns}
                   loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                   onChange={handleTableChange} scroll={{x: 'auto'}}
                   pagination={{
                      total: totalCount
                   }}
                   rowSelection={{...rowSelection}}/>
         </div>

      </div>
   );
};


export default ControlNotifications;
