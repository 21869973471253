import React, { useCallback, useEffect, useState } from "react";
import api from "../service";
import qs from "qs";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";

const { TextArea } = Input;

const Contact = () => {
    const [data, setData] = useState([]);
    const [validationCheck, setValidationCheck] = useState(false)
    const [errors, setErrors] = useState([])
    const history = useHistory()
    const validate = useCallback(() => {
        let errors = {};

        if (data.title == null || data.title.length === 0)
            errors.title = "Zorunlu Alan";
        if (data.content == null || data.content.length === 0)
            errors.content = "Zorunlu Alan";
        if (data.mail == null || data.mail.length === 0)
            errors.mail = "Zorunlu Alan";
        if (data.phone == null || data.phone.length === 0)
            errors.phone = "Zorunlu Alan";


        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    const getData = async () => {
        const query = [];
        const _params = { query }
        const restData = await api.get(`/api/contact?${qs.stringify(_params)}`, _params)
            .then(({ data }) => {
                if (data && data.result) {
                    return data.result.map((item, key) => {
                        item.key = key;
                        return item;
                    })
                }
            })
            .catch(err => {
                console.log(err);
            });
        setData(restData[0]);
    }
    useEffect(() => {
        getData();
    }, [])
    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        } else {
            await api.put(`/api/contact/${data.id}`, data)
                .then(({ data: { result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success("Kayıt Başarıyla Güncellendi ...", 2);
                        history.push('/contact')
                    } else
                        message.error("Kayıt Güncellenemedi !", 2);
                })
                .catch(err => {
                    console.log(err);
                    message.error("Kayıt Güncellenemedi !", 2);
                })
        }
    };

    const phoneMask = (phoneData) => {
        setData({ ...data, phone: phoneData })
    };
    const PhoneNumber = props => (
        <InputMask
            mask="(999) 999-9999"
            value={data.phone}
            onChange={e => phoneMask(e.target.value)}
        >
        </InputMask>
    );
    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>İletişim</h1>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={"Düzenle"}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Başlık" required help={errors.title}
                                    validateStatus={errors.title ? 'error' : 'success'}>
                                    <TextArea rows={2} name="content" value={data.title}
                                        onChange={e => setData({ ...data, title: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Açıklama" required help={errors.content}
                                    validateStatus={errors.content ? 'error' : 'success'}>
                                    <TextArea rows={4} name="content" value={data.content}
                                        onChange={e => setData({ ...data, content: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="E-mail" required help={errors.mail}
                                    validateStatus={errors.mail ? 'error' : 'success'}>
                                    <Input name="mail" value={data.mail}
                                        onChange={e => setData({ ...data, mail: e.target.value })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={"Telefon"} required={true}
                                    help={errors.phone} validateStatus={errors.phone ? 'error' : 'success'}>
                                    {/* <Input type="number" pattern="[0-9]*" className='phoneInput' placeholder="(XXX) XXX XX XX" value={data.phone} 
                                    onChange={e => phoneMask(e.target.value)} /> */}
                                    <InputMask
                                        mask="(999) 999-9999"
                                        style={{ width: '100%' }}
                                        placeholder="(XXX) XXX-XXXX"
                                        value={data.phone}
                                        onChange={e => phoneMask(e.target.value)}    >
                                        {inputProps => <Input {...inputProps} fullWidth />}

                                    </InputMask>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={24}>
                                <Form.Item>
                                    <Button
                                        style={{ backgroundColor: '#e12518', color: 'white' }} htmlType="submit" size="large"
                                        block> {"KAYDET"} </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </div>
        </div>
    );
}

export default Contact;