import React, {useEffect, useGlobal} from "reactn";
import {Route, Switch} from 'react-router-dom';
import {Layout} from 'antd';
import SideMenu from './layout/SideMenu';
import HeaderBar from './layout/HeaderBar';
import {
    Dashboard, Attendee, AttendeeDetail, 
    NotFoundPage, Announcements, AnnouncementDetail, 
    ControlNotifications, ControlNotificationDetail, OurBank,
    OurBankDetail,Education, Contact,
    PushNotification, PushNotificationDetail
    
} from './pages';

const {Header, Content} = Layout;

const Admin = (props) => {
    let [token, setToken] = useGlobal('token');
    let [user] = useGlobal('user');
    let [isSmall, setIsSmall] = useGlobal('isSmall')
    let [collapsed, setCollapsed] = useGlobal('collapsed');

    useEffect(() => {
        window.onresize = () => {
            setIsSmall(window.innerWidth < 1024)
        }
    }, [setIsSmall, setToken])
    useEffect(() => {
        if (isSmall)
            setCollapsed(true)
        else
            setCollapsed(false)
    }, [isSmall, setCollapsed])

    if (!token || !user) {
        props.history.push('/login');
    }

    return (
        <Switch>

            <Route>
                <Layout>
                    <SideMenu collapsed={collapsed}/>
                    <Layout className="site-layout" style={{minHeight: '100vh'}}>
                        <Header className="site-layout-background header" style={{padding: '0 20px'}} theme="dark">
                            <HeaderBar/>
                        </Header>

                        <Content className="main-wrap">
                            {token && (
                                <>
                                    <Switch>

                                        <Route exact path="/attendees" component={Attendee}/>
                                        <Route exact path="/" component={Attendee}/>

                                        <Route exact path="/adminInfo" component={Dashboard}/>

                                        <Route exact path="/attendees/edit/:id" component={AttendeeDetail}/>
                                        <Route exact path="/attendees/detail/:id" component={AttendeeDetail}/>
                                        <Route exact path="/attendees/add" component={AttendeeDetail}/>

                                        <Route exact path="/announcements" component={Announcements}/>
                                        <Route exact path="/announcement/edit/:id" component={AnnouncementDetail}/>
                                        <Route exact path="/announcement/detail/:id" component={AnnouncementDetail}/>
                                        <Route exact path="/announcement/add" component={AnnouncementDetail}/>

                                        <Route exact path="/controlNotifications" component={ControlNotifications}/>
                                        <Route exact path="/controlNotification/edit/:id" component={ControlNotificationDetail}/>
                                        <Route exact path="/controlNotification/detail/:id" component={ControlNotificationDetail}/>
                                        <Route exact path="/controlNotification/add" component={ControlNotificationDetail}/>

                                        <Route exact path="/ourBank" component={OurBank}/>
                                        <Route exact path="/ourBank/edit/:id" component={OurBankDetail}/>
                                        <Route exact path="/ourBank/detail/:id" component={OurBankDetail}/>
                                        <Route exact path="/ourBank/add" component={OurBankDetail}/>

                                        <Route exact path="/notifications" component={PushNotification} />
                                        <Route exact path="/notification/detail:id" component={PushNotificationDetail} />
                                        <Route exact path="/notification/edit/:id" component={PushNotificationDetail} />
                                        <Route exact path="/notification/add" component={PushNotificationDetail} />

                                        <Route exact path="/education" component={Education}/>

                                        <Route exact path="/contact" component={Contact}/>

                                        <Route path='*' component={NotFoundPage}/>
                                    </Switch>
                                </>
                            )}

                        </Content>
                    </Layout>
                </Layout>
            </Route>
        </Switch>
    );
};

export default Admin;
